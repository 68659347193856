import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { GrDocumentPdf } from "react-icons/gr"
// Types //
import { ClientInterface } from "../../@types/client.types";
import { KlipboxFiltroRelatorio } from "../../@types/klipbox.types";
// Context //
import { useKlipbox } from "../../context/klipbox.context";
// Service //
import { AlertsService } from "../../services/alerts.service";
import { RequestsService } from "../../services/requests.service";
// CSS //
import './klipbox-filtro-relatorio.css'
// Component //
import FiltroItemComponent from "../filtro-item";
import ButtonComponent from "../button";
import ComponentInfo from "../info";
import { BiInfoCircle, BiLoaderAlt, BiLoaderCircle } from "react-icons/bi";
import { convertMsToTime } from "../../common/utilitaries";

const KlipboxFiltroRelatorioComponent = (props: KlipboxFiltroRelatorio) => {

    const location = useLocation()
    const { fetchClients } = useKlipbox()
    const [clients, setClients] = useState({ isFetched: false, data: [{
        id: -1,
        name: "Clientes",
        sigla: "CP",
    }] } as ClientInterface)

    useEffect(() => {
        const requestClients = setTimeout(() => {
            // Verificando se os clientes não foram buscados //
            if (clients.isFetched)
                return;

            fetchClients()
                // Sucesso //
                .then((clients) => {
                    // Obtendo a lista //
                    const _clients = [{ id: -1, name: "Clientes", sigla: "CLI", avatar: "", capa: "", created_at: new Date(), updated_at: new Date() }]
                    clients.data.forEach((value) => _clients.push(value))
                    // Setando //
                    setClients({ isFetched: true, data: _clients })
                })
                // Error //
                .catch(() => AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar os clientes cadastrados." }))
        }, 0)

        return () => clearTimeout(requestClients)
    }, [location.pathname])

    return (
        <div className="component-klipbox-relatorio-filtros">
            {/* Cliente */}
            <FiltroItemComponent
                type="select"
                id="clientId"
                name="clientId"
                title="cliente"
                onChange={props.updateFilter}
                childrens={
                    clients.data.map((value, index) => (
                        <option key={index} value={value.id}>{value.name}</option>
                    ))
                }
                style={{ width: "240px" }}
            />

            {/* Data */}
            <FiltroItemComponent
                type="date"
                id="date"
                name="date"
                dateChange={props.dateChange}
                dateShow={props.dateShow}
            />

            {/* Gerar Preview */}
            <ButtonComponent
                text="Gerar preview"
                type="submit"
                onClick={props.generatePreview}
                class="component-klipbox-relatorio-button"
            />

            {/* Resetar */}
            <ButtonComponent
                text="Resetar"
                type="reset"
                onClick={props.resetPreview}
                class="component-klipbox-relatorio-button component-klipbox-relatorio-button-reset"
            />

            {/* Info */}
            <ComponentInfo
                icon={<BiInfoCircle />}
                text="Os dados gerados são salvos em cache por aproximadamente <strong>10 minutos</strong> antes de serem gerados novos dados."
                style={{
                    marginLeft: ".7vw",
                }}
            />

            {/* GERAR PDV */}
            {!props.previewIsGenerated ? <></> :
                (
                    <>
                        <ComponentInfo
                            icon={<BiLoaderAlt />}
                            text={
                                props.generateInMs == -1 ? 
                                    "Ocorreu um <strong style='color: #f3b7bd;'>erro</strong> na busca dos dados." : 
                                    "Os dados foram buscados com <strong style='color: #86e29b;'>sucesso</strong> em <strong>" + convertMsToTime(props.generateInMs) + "ms.</strong>"
                            }
                            style={{
                                marginLeft: ".5vw"
                            }}
                        />

                        <button title="Gerar PDF do relatório" onClick={props.generatePDF} className="component-klipbox-relatorio-pdf">
                            <GrDocumentPdf stroke="white" />
                        </button>
                    </>
                )
            }
        </div>
    )
}

export default KlipboxFiltroRelatorioComponent;