import React from "react"
import { ActionProps } from "../../@types/component.types"

// CSS //
import "./action.css"

/**
 * @name ActionComponent - Cria um ícone de ação 
 * 
 * @param props 
 * 
 * @returns Component
 */
const ActionComponent = (props: ActionProps) => {
    const addProps = props.class === undefined ? "" : " " + props.class
    const className = "component-action" + addProps

    const buildIcon = () => {
        if (!props.hasIcon)
            return;
        return (
            <div className="component-action-icon">
                <span>{props.icon}</span>
            </div>
        )
    }

    const buildName = () => {
        if (!props.name)
            return;
        return (
            <p className="component-action-name">
                <span>{props.name}</span>
            </p>
        )
    }

    const buildDropdrown = () => {
        if (!props.hasDropdown)
            return
        return (
            <React.Fragment>
                {props.dropDown}
            </React.Fragment>
        )
    }

    return (
        <div className={className} onClick={props.onClick}>
            {/* NAME */}
            {buildName()}

            {/* ICON */}
            {buildIcon()}

            {/* Dropdown */}
            {buildDropdrown()}
        </div>
    )
}

export default ActionComponent