import { BiBell } from "react-icons/bi"
import { HiDesktopComputer } from "react-icons/hi"
import { MenuPrivateProps } from "../../@types/component.types"
import { environment } from "../../common/environments"

// Component //
import ActionComponent from "../../component/action"
import DropDownComponent from "../../component/dropdown"
import MenuComponent from "../../component/menu"
import SubMenuItemComponent from "../../component/sub-menu-item"

const Dashboard = (props: MenuPrivateProps) => {

    return (
        <div className="dashboard">
            <MenuComponent name="Dashboard" groupsList={props.groupsList} />

            <div className="dashboard-content">
                {/* Repercussão */}
                <div className="dashboard-header">

                    {/* Dashboard Sub Menu */}
                    <div className="dashboard-sub-menu">

                        {/* Sub Menus */}
                        <div className="dashboard-sub-menus">
                            <SubMenuItemComponent
                                name="Monitoramento"
                                hasIcon={true}
                                icon={<HiDesktopComputer />}
                                redirectTo={environment.ROUTES.DASHBOARD}
                            />
                        </div>

                        {/* Actions */}
                        <div className="dashboard-sub-menu-actions">
                            {/* Notifications */}
                            <ActionComponent
                                hasIcon={true}
                                icon={<BiBell />}
                                hasDropdown={true}
                                dropDown={
                                    <DropDownComponent
                                        isNotification={true}
                                    />
                                }
                            />
                        </div>
                    </div>

                    {/* Dashboard User */}
                    <div className="dashboard-user-menu">

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard