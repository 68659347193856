/**
 * @name saveToken - Salva o token no Session Storage
 * 
 * @param token 
 * 
 * @returns Function
 */
 const saveToken = (token: any) => {
    return sessionStorage.setItem("session@token", token)
}

/**
 * @name saveSession - Salva as informações do usuário na Session Storage
 * 
 * @param data 
 * 
 * @returns Function
 */
const saveSession = (data: any) => {
    return sessionStorage.setItem("session@data", JSON.stringify(data))
}

/**
 * @name getToken - Obtém o token de acesso do usuário
 * 
 * @returns String
 */
const getToken = () => {
    return sessionStorage.getItem("session@token");
}

/**
 * @name getData - Obtém a data do usuário
 * 
 * @returns String
 */
const getData = () => {
    return sessionStorage.getItem("session@data") || "";
}

/**
 * @name hasData - Verifica se existe a data do usuário
 * 
 * @returns Boolean
 */
const hasData = () => {
    return sessionStorage.getItem("session@data") != null
}

/**
 * @name removeToken - Remove as credenciais expiradas 
 * 
 * @returns Boolean
 */
const removeToken = () => {
    sessionStorage.removeItem("session@data")
    sessionStorage.removeItem("session@token")
    return;
}

const setFetching = (value: boolean) => {
    return sessionStorage.setItem("klipbox.news@fetching", JSON.stringify(value))
}

const isFetching = (): boolean => {
    return (sessionStorage.getItem("klipbox.news@fetching") === "true");
}

export const StorageService = {
    saveToken,
    saveSession,
    getToken,
    getData,
    hasData,
    removeToken,

    setFetching,
    isFetching,
}