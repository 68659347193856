// CSS //
import "./klipbox-noticia-skeleton.css"

const KlipboxNoticiaSkeleton = () => {

    return (
        <div className="component-klipbox-noticia-skeleton">
            {/* DATA E CLIENTE */}
            <div className="component-klipbox-noticia-skeleton-header">
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">(12345) Klipbox Noticia Skeleton | IMTracing - iMarketing Agência Digital</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">11/08/2022</p>
            </div>

            {/* TITLE */}
            <div className="component-klipbox-noticia-skeleton-title">
                <h1 className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai 1</h1>
            </div>

            {/* LINK */}
            <div className="component-klipbox-noticia-skeleton-filtros component-klipbox-noticia-link">
                <p>Link da matéria</p>

                <div className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading component-klipbox-noticia-skeleton-filtro" />
            </div>

            {/* FILTROS LIST*/}
            <div className="component-klipbox-noticia-filtros-list">
                {/* CLASSIFICATION */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Classificação</p>
                    <div className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading component-klipbox-noticia-skeleton-filtro" />
                </div>

                {/* IA CLASSIFICATION */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Saven IA</p>
                    <div className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading component-klipbox-noticia-skeleton-filtro" />
                </div>

                {/* CLIENT */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Cliente</p>
                    <div className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading component-klipbox-noticia-skeleton-filtro" />
                </div>

                {/* MONITORING */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Monitoramento</p>
                    <div className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading component-klipbox-noticia-skeleton-filtro" />
                </div>
            </div>

            {/* MATERIA CONTENT */}
            <div className="component-klipbox-noticia-skeleton-content">
                <h1>Matéria</h1>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
                <p className="component-klipbox-noticia-skeleton-body component-klipbox-noticia-skeleton-loading">Esse talvez seja um título esperado para que seja mostrado aqui. Talves sim, talvez não. Não sei. Vamos esperar para ver. Ai talvez tenha algo aqui. 111|</p>
            </div>
        </div>
    )

}

export default KlipboxNoticiaSkeleton;