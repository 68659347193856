import { Pie } from "@ant-design/plots"
import moment from "moment"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { PieData } from "../../../../@types/graphs.data.types"
import { KlipboxGraphPieResponse } from "../../../../@types/klipbox.response.types"
import { environment } from "../../../../common/environments"
import { useKlipbox } from "../../../../context/klipbox.context"
import { AlertsService } from "../../../../services/alerts.service"

// CSS //
import "./private.css"

const ComponentGraphPie = () => {

    const { fetchGraph } = useKlipbox()

    const [pieData, setPieData] = useState({} as PieData[])
    const [isLoading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(window.location.search)
    const accessToken = queryParams.get("accessToken")
    const token = queryParams.get("token")
    const startDate = queryParams.get("startDate")
    const endDate = queryParams.get("endDate")
    const clientId = queryParams.get("clientId")

    useEffect(() => {
        const params = {
            clientId: clientId || 2,
            startDate: startDate || moment().startOf('month').format("YYYY-MM-DD"),
            endDate: endDate || moment().endOf('month').format("YYYY-MM-DD"),
            token: accessToken
        }

        const getData = setTimeout(async () => {
            // Verificando se existe acesso //
            if (!token || token !== environment.URLS.GRAPH_TOKEN) {
                return
            }

            // PieData //
            const response: KlipboxGraphPieResponse = await fetchGraph(environment.URLS.KLIPBOX.GRAPH.PIE, params)
            if (response) {
                const { success, data } = response
                if (success && data) {
                    const result = []
                    result.push({ type: "Positivas", value: data.positivas })
                    result.push({ type: "Negativas", value: data.negativas })
                    result.push({ type: "Neutras", value: data.neutras })

                    // Setando os dados da pizza //
                    setPieData(result)
                    setLoading(false)
                }
            }
        })

        return () => clearTimeout(getData)
    }, [])

    if (!token || token !== environment.URLS.GRAPH_TOKEN) {
        AlertsService.notification({ icon: "error", title: "Você não tem permissões suficientes para acessar essa rota." })
        return <Navigate to={environment.ROUTES.DASHBOARD} replace />
    }

    if (isLoading) {
        return <></>
    }

    return (
        <div className="private-graph">
            <Pie
                appendPadding={10}
                data={pieData}
                angleField="value"
                colorField="type"
                radius={0.75}
                legend={{
                    position: "right",
                    itemName: { style: { fill: "black", fontSize: 30 } },
                }}
                label={{
                    type: 'spider',
                    content: '{name} {percentage}',
                    style: { fill: "black", fontSize: 30 }
                }}
                theme={{
                    styleSheet: { fontFamily: "Poppins" }
                }}
                interactions={[
                    { type: 'pie-legend-active' },
                    { type: 'element-active' },
                ]}
                color={["#2e75b5", "#a50200", "#fcbd32"]}
                className="component-pie-canvas private-graph"
            />
        </div>
    )
}

export default ComponentGraphPie