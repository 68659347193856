// CSS //
import './line-skeleton.css'

const ComponentLineSkeleton = () => {
    return (
        <div className="component-line-skeleton">
            <div className="klipbox-graph-title">
                <h1 className="klipbox-skeleton-body klipbox-skeleton-loading">Component Pie Skeleton Loading</h1>
            </div>
            <div className="klipbox-graph-legend">
                <p className="klipbox-skeleton-body klipbox-skeleton-loading">Component Pie Skeleton Legend Loading. Well, i'ts working! Nice!</p>
            </div>

            <div className="component-line-skeleton-graph">
                {/* LEGEND */}
                <div className="component-line-skeleton-graph-legends">
                    <p className="klipbox-skeleton-body klipbox-skeleton-loading">Legenda 1</p>
                    <p className="klipbox-skeleton-body klipbox-skeleton-loading">Legenda 2</p>
                    <p className="klipbox-skeleton-body klipbox-skeleton-loading">Legenda 3</p>
                </div>

                {/* GRAPH */}
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    flexWrap: "wrap",
                    marginTop: ".8vw",
                }}>
                    {Array(8).fill(0).map((item, index) => (
                        <div key={index} className="klipbox-skeleton-body klipbox-skeleton-loading component-line-skeleton-graph-line" />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default ComponentLineSkeleton;