// Stylesheet //
import { CSSProperties } from "react";
import { InputProps } from "../../@types/component.types";

import "./input.css"

/**
 * @name InputComponent - Component dos input
 * 
 * @param props 
 * 
 * @returns Component
 */
const InputComponent = (props: InputProps) => {
    const className = "component-input" + (props.class === undefined ? "" : " " + props.class)

    const buildInput = (className: string, style: CSSProperties | undefined) => {
        return (
            <input
                className={className}
                name={props.name}
                placeholder={props.placeholder}
                autoComplete={props.autoComplete ? "on" : "off"}
                type={props.type}
                style={style}
                onChange={props.onChange}
            />
        )
    }

    // Quando o input existe um ícone //
    if (props.hasIcon) {
        return (
            <div className="input-components">
                {/* Icons */}
                <span>
                    {props.icon}
                </span>

                {/* Building input property */}
                {buildInput(props.class || "", {})}
            </div>
        )
    }

    // Quando o input é um label //
    if (props.hasLabel) {
        return (
            <div className="input-components-label">
                {/* Building input property */}
                {buildInput(props.class || "", props.style)}

                {/* Label */}
                <label htmlFor={props.name} className="component-input-label">
                    {props.label}
                </label>
            </div>
        )
    }

    return buildInput(className, props.style)
}

export default InputComponent