import React from "react"
import { BiUser, BiUserCircle } from "react-icons/bi"
import { environment } from "../../../common/environments"

// Component //
import SubMenuItemComponent from "../../../component/sub-menu-item"
import DashboardHeader from "../../dashboard/header"

const ConfiguracaoDashboardHeader: React.FC = () => {

    /*
    
            <SubMenuItemComponent 
                name="Clientes"
                hasIcon={true}
                icon={<BiUserCircle />}
                redirectTo={environment.ROUTES.CONFIGURACOES.CLIENTES} 
            />

    */

    return (
        <DashboardHeader>
            <SubMenuItemComponent
                name="Usuários"
                hasIcon={true}
                icon={<BiUser />}
                redirectTo={environment.ROUTES.CONFIGURACOES.USUARIOS} 
            />
        </DashboardHeader>
    )

}

export default ConfiguracaoDashboardHeader