import React from "react"
import { BiNews } from "react-icons/bi"
import { HiDocumentReport } from "react-icons/hi"
import { environment } from "../../../common/environments"

// Component //
import SubMenuItemComponent from "../../../component/sub-menu-item"
import DashboardHeader from "../../dashboard/header"

const KlipboxDashboardHeader: React.FC = () => {

    return (
        <DashboardHeader>
            <SubMenuItemComponent
                name="Notícias"
                hasIcon={true}
                icon={<BiNews />}
                redirectTo={environment.ROUTES.KLIPBOX.REPERCUSSAO}
            />
            <SubMenuItemComponent
                name="Relatório"
                hasIcon={true}
                icon={<HiDocumentReport />}
                redirectTo={environment.ROUTES.KLIPBOX.RELATORIO}
            />
        </DashboardHeader>
    )

}

export default KlipboxDashboardHeader