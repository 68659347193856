import { Bar } from "@ant-design/plots"
import moment from "moment"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { BarData } from "../../../../@types/graphs.data.types"
import { KlipboxGraphRankingResponse } from "../../../../@types/klipbox.response.types"
import { environment } from "../../../../common/environments"
import { useKlipbox } from "../../../../context/klipbox.context"
import { AlertsService } from "../../../../services/alerts.service"

// CSS //
import "./private.css"

const ComponentGraphBarCategory = () => {

    const { fetchGraph } = useKlipbox()

    const [barDataRanking, setBarDataRanking] = useState({} as BarData[])
    const [isLoading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(window.location.search)
    const accessToken = queryParams.get("accessToken")
    const token = queryParams.get("token")
    const startDate = queryParams.get("startDate")
    const endDate = queryParams.get("endDate")
    const clientId = queryParams.get("clientId")

    useEffect(() => {
        const params = {
            clientId: clientId || 2,
            startDate: startDate || moment().startOf('month').format("YYYY-MM-DD"),
            endDate: endDate || moment().endOf('month').format("YYYY-MM-DD"),
            token: accessToken
        }

        const getData = setTimeout(async () => {
            // Verificando se existe acesso //
            if (!token || token !== environment.URLS.GRAPH_TOKEN) {
                return
            }

            // PieData //
            const response: KlipboxGraphRankingResponse = await fetchGraph(environment.URLS.KLIPBOX.GRAPH.MONITORING_RANKING, params)
            if (response) {
                const { success, data } = response
                if (success && data) {
                    const result = [] as BarData[]

                    // Ordenando do maior para o menor //
                    data.sort((a, b) => {
                        const totalB = (b.positivas + b.negativas + b.neutras)
                        const totalA = (a.positivas + a.negativas + a.neutras)
                        if (totalB > totalA) return 1;
                        if (totalB < totalA) return -1;
                        return 0
                    })

                    // Fazendo o parse dos dados //
                    data.forEach((item) => {
                        let monitoringName = undefined
                        if (item.monitoramento.indexOf("GOV") !== -1) {
                            monitoringName = item.monitoramento.split(" - ")[1]
                            if (monitoringName.indexOf("G1") !== -1) {
                                monitoringName = "Governo do Amazonas"
                            }
                        }

                        result.push({ primary: monitoringName || item.monitoramento, type: "Positivas", value: item.positivas })
                        result.push({ primary: monitoringName || item.monitoramento, type: "Neutras", value: item.neutras })
                        result.push({ primary: monitoringName || item.monitoramento, type: "Negativas", value: item.negativas })
                    })

                    // Setando os resultados //
                    setBarDataRanking(result)
                    setLoading(false)
                }
            }
        })

        return () => clearTimeout(getData)
    }, [])

    if (!token || token !== environment.URLS.GRAPH_TOKEN) {
        AlertsService.notification({ icon: "error", title: "Você não tem permissões suficientes para acessar essa rota." })
        return <Navigate to={environment.ROUTES.DASHBOARD} replace />
    }

    if (isLoading) {
        return <></>
    }

    return (
        <div className="private-graph">
            <Bar
                data={barDataRanking}
                isStack={true}
                xField="value"
                yField="primary"
                seriesField="type"
                className="component-bar-canvas"
                color={["#2e75b5", "#fcbd32", "#a50200"]}
                label={{
                    position: "middle",
                    style: { fontFamilly: "Poppins", fill: "black", fontSize: 24 },
                }}
                legend={{
                    position: "top",
                    itemName: { style: { fontFamily: "Poppins", fill: "black", fontSize: 26 } }
                }}
                yAxis={{
                    label: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 30 },
                        formatter: (v) => v.toUpperCase()
                    }
                }}
                xAxis={{
                    label: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 26, textAlign: "end" }
                    }
                }}
            />
        </div>
    )
}

export default ComponentGraphBarCategory