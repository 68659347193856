import React, { useState } from "react"

import { BiBell } from "react-icons/bi";
import ActionComponent from "../../../component/action";
import DropDownComponent from "../../../component/dropdown";

const DashboardHeader = ({ children }: { children: React.ReactNode }) => {

    const [isActived] = useState(false)

    return (
        <div className="dashboard-header">
            {/* Dashboard Sub Menu */}
            <div className="dashboard-sub-menu">
                {/* Sub Menu */}
                <div className="dashboard-sub-menus">
                    {children}
                </div>

                <div className="dashboard-sub-menu-actions">
                    {/* Notifications */}
                    {
                        !isActived ? <></> :
                        <ActionComponent
                            hasIcon={true}
                            icon={<BiBell />}
                            hasDropdown={true}
                            dropDown={
                                <DropDownComponent isNotification={true} />
                            }
                        />
                    }
                </div>
            </div>

            {/* Dashboard User */}
            <div className="dashboard-user-menu">

            </div>
        </div>
    )
}

export default DashboardHeader;