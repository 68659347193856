export const environment = {
    VERSION: process.env.REACT_APP_VERSION || "develop-base-0.0.1",

    SERVER: {
        PORT: process.env.REACT_APP_PORT || 3000,
        BASEURL: process.env.REACT_APP_BASEURL || "http://localhost:3000/api",
        DEFAULT_PASSWORD: process.env.REACT_APP_DEFAULTPASSWORD || "localhost123@321"
    },

    GROUPS: {
        ADMIN: "Admin",
        MONITORAMENTO: "Monitoramento"
    },

    ROUTES: {
        DASHBOARD: "/dashboard",
        KLIPBOX: {
            REPERCUSSAO: "/klipbox/repercussao",
            RELATORIO: "/klipbox/repercussao/relatorio"
        },
        CONFIGURACOES: {
            USUARIOS: "/configuracoes/usuarios",
            CLIENTES: "/configuracoes/clientes"
        },
        GRAPHS: {
            PIE: "/graphs/private/pie",
            LINE: "/graphs/private/line",
            BAR_TOTAL: "/graphs/private/barTotal",
            BAR_CATEGORY: "/graphs/private/barCategory",
        },
        TOP10: "/top10/:id"
    },

    URLS: {
        // BASE //
        BASE: {
            NEWS: process.env.REACT_APP_BASE_NEWS_URL || process.env.REACT_APP_BASEURL,
            ARCHIVE: process.env.REACT_APP_ARCHIVE_NEWS_URL || process.env.REACT_APP_BASEURL
        },

        GRAPH_TOKEN: process.env.REACT_APP_GRAPH_TOKEN || "ViniciuszXLMelhorDoMundoGraçasADeusHeheObrigadoSenhorSirrrrr",
        USER: {
            CREATE: "/user",
            LOGIN: "/user/authenticate",
            INFO: "/user",
            LIST: "/users",
            DEL: "/user/",
            RESTORE: "/user/restore/"
        },
        CLIENTS: {
            LIST: "/clients"
        },
        GROUPS: {
            LIST: "/groups"
        },
        KLIPBOX: {
            CREATE: "/klipbox",
            LIST: "/klipbox",
            COUNT: "/klipbox/count",
            GET: "/klipbox/",
            UPDATE: "/klipbox/",
            DEL: "/klipbox/",
            MONITORING: {
                LIST: "/klipbox/monitorings"
            },
            GRAPH: {
                PIE: "/klipbox/graph/pie",
                DAILY: "/klipbox/graph/daily",
                MONITORING_TOTAL: "/klipbox/graph/monitoringTotal",
                MONITORING_RANKING: "/klipbox/graph/monitoringRanking",
                RANKING_PORTAIS: "/klipbox/graph/rankingPortais",
                TOP10_CITACOES: "/klipbox/graph/top10Citacoes",
            },
            TOP10: "/klipbox/newsById/"
        },
        ARCHIVE: {
            PDF: {
                REPERCUSSAO: "/pdf/repercussao"
            }
        }
    }
}