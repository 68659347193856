// CSS //
import './pie-skeleton.css'

const ComponentPieSkeleton = () => {
    return (
        <div className="component-pie-skeleton">
            <div className="klipbox-graph-title">
                <h1 className="klipbox-skeleton-body klipbox-skeleton-loading">Component Pie Skeleton Loading</h1>
            </div>
            <div className="klipbox-graph-legend">
                <p className="klipbox-skeleton-body klipbox-skeleton-loading">Component Pie Skeleton Legend Loading. Well, i'ts working! Nice!</p>
            </div>

            <div className="component-pie-skeleton-graph">
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                }}>
                    {/* CIRCLE */}
                    <div className="klipbox-skeleton-body klipbox-skeleton-loading component-pie-skeleton-graph-circle" />
                </div>
                {/* LEGEND */}
                <div className="component-pie-skeleton-graph-legends">
                    <p className="klipbox-skeleton-body klipbox-skeleton-loading">Legenda 1</p>
                    <p className="klipbox-skeleton-body klipbox-skeleton-loading">Legenda 2</p>
                    <p className="klipbox-skeleton-body klipbox-skeleton-loading">Legenda 3</p>
                </div>
            </div>
        </div>
    )
}

export default ComponentPieSkeleton;