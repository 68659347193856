import React, { useState, useRef } from "react"
import { BiFilterAlt } from "react-icons/bi"
import moment from "moment"
import fileDownload from 'js-file-download'
import Swal from "sweetalert2"

// Types //
import { DateRangeProps, FiltroStateProps, MenuPrivateProps } from "../../../@types/component.types"
import { KlipboxGraphDailyResponse, KlipboxGraphPieResponse, KlipboxGraphRankingPortaisResponse, KlipboxGraphRankingResponse, KlipboxGraphTop10Citacoes, KlipboxGraphTotalResponse } from "../../../@types/klipbox.response.types"
import { BarData, LineData, PieData } from "../../../@types/graphs.data.types"

// Services //
import { AlertsService } from "../../../services/alerts.service"

// Context //
import { useKlipbox } from "../../../context/klipbox.context"

// Component //
import KlipboxFiltroRelatorioComponent from "../../../component/klipbox-filtro-relatorio"
import MenuComponent from "../../../component/menu"
import KlipboxDashboardHeader from "../header"
import ComponentLineGraph from "../../../component/graphs/line"
import ComponentPieGraph from "../../../component/graphs/pie"
import ComponentBarGraph from "../../../component/graphs/bar"
import ComponentInteraction from "../../../component/interaction"

// Environment //
import { environment } from "../../../common/environments"
import { RequestsService } from "../../../services/requests.service"
import { Top10Citacoes } from "../../../@types/top10.types"
import { formatingMonitoringName } from "../../../common/utilitaries"

const KlipboxRepercussaoRelatorio = (props: MenuPrivateProps) => {

    const { fetchGraph } = useKlipbox()

    // References //
    const pieGraphReference = useRef()

    const [isPreviewGenerated, setPreviewGenerated] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [generateInMs, setGenerateInMs] = useState(1500)

    // Datas //
    const [pieData, setPieData] = useState({} as PieData[])
    const [lineData, setLineData] = useState({} as LineData[])
    const [barDataGeral, setBarDataGeral] = useState({} as BarData[])
    const [barDataRanking, setBarDataRanking] = useState({} as BarData[])
    const [top10CitacoesPositivas, setTop10CitacoesPositivas] = useState({} as Top10Citacoes[])
    const [top10CitacoesNegativas, setTop10CitacoesNegativas] = useState({} as Top10Citacoes[])
    const [rankingPortais, setRankingPortais] = useState({} as BarData[])

    const [filtro, setFiltro] = useState({ clientId: -1 } as FiltroStateProps)
    const [date, setDate] = useState([] as DateRangeProps)

    /**
     * @name changeDataRange - Atualiza as datas de início e fim
     * 
     * @param value 
     */
    const changeDateRange = (value: string | Date) => {
        if (!value) {
            setDate({})
        } else {
            const split = value.toString().split(",")
            setDate({ startDate: new Date(split[0]), endDate: new Date(split[1]) })
        }
    }

    /**
     * @name generatePreview - Gera um preview
     * 
     * @param e 
     * @returns 
     */
    const generatePreview = async (e: any) => {
        e.preventDefault()

        const millisAt = moment().valueOf()

        setPreviewGenerated(false)
        // Preview já sendo carregado //
        if (isLoading)
            return;
        // Atribuindo a data //
        filtro.date = date
        // Verifica se foi selecionado um cliente //
        if (filtro.clientId === -1) {
            return AlertsService.notification({
                icon: 'error',
                titleText: "Você não selecionou um cliente para gerar o preview do relatório"
            })
        }

        const _startDate = moment(filtro.date?.startDate || moment())
        const _endDate = moment(filtro.date?.endDate || moment())

        // Params //
        const params = {
            clientId: filtro.clientId || 2,
            startDate: filtro.date.startDate !== undefined ? _startDate.format("YYYY-MM-DD") : _startDate.startOf('month').format("YYYY-MM-DD"),
            endDate: filtro.date.endDate !== undefined ? _endDate.format("YYYY-MM-DD") : _endDate.endOf('month').format("YYYY-MM-DD")
        }

        setLoading(true)
        let hasError = false

        // Graph requests //
        const [
            pieGraph,
            dailyGraph,
            totalGraph,
            rankingGraph,
            top10CitacoesPositivas,
            top10CitacoesNegativas,
            rankingPortais,
        ] = await Promise.all([
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.PIE, params),
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.DAILY, params),
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.MONITORING_TOTAL, params),
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.MONITORING_RANKING, params),
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.TOP10_CITACOES, {...params, classification: "Positiva"}),
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.TOP10_CITACOES, {...params, classification: "Negativa"}),
            fetchGraph(environment.URLS.KLIPBOX.GRAPH.RANKING_PORTAIS, params),
        ])

        // Pie Graph //
        if (!pieGraph || (pieGraph && !pieGraph.success)) {
            hasError = true
        } else {
            const { data } : KlipboxGraphPieResponse = pieGraph
            if (data) {
                // Setando os dados da pizza //
                setPieData([
                    { type: "Positivas", value: data.positivas },
                    { type: "Negativas", value: data.negativas },
                    { type: "Neutras", value: data.neutras }
                ])
            }
        }

        // Daily Graph //
        if (!dailyGraph || (dailyGraph && !dailyGraph.success)) {
            hasError = true
        } else {
            const { data }: KlipboxGraphDailyResponse = dailyGraph
            if (data) {
                const result = [] as LineData[]

                // Fazendo o parse dos dados //
                data.forEach((item) => {
                    result.push(
                        { date: item.date + `/${moment().format("YYYY")}`, classification: "Positivas", value: item.positivas },
                        { date: item.date + `/${moment().format("YYYY")}`, classification: "Negativas", value: item.negativas },
                        { date: item.date + `/${moment().format("YYYY")}`, classification: "Neutras", value: item.neutras },
                    )
                })

                // Setando os resultados //
                setLineData(result)
            }
        }

        // Total Graph //
        if (!totalGraph || (totalGraph && !totalGraph.success)) {
            hasError = true
        } else {
            const { data }: KlipboxGraphTotalResponse = totalGraph
            if (data) {
                const result = [] as BarData[]

                // Fazendo o parse dos dados //
                data.forEach((item) => {
                    let monitoringName = undefined
                    if (item.monitoramento.indexOf("GOV") !== -1) {
                        monitoringName = item.monitoramento.split(" - ")[1]
                        if (monitoringName.indexOf("G1") !== -1) {
                            monitoringName = "Governo do Amazonas"
                        }
                    }

                    result.push({
                        primary: monitoringName || item.monitoramento,
                        type: "Total",
                        value: item.total
                    })
                })

                // Ordenando do maior para o menor //
                data.sort((a, b) => b.total > a.total ? 1 : 0)

                // Setando os resultados //
                setBarDataGeral(result)
            }
        }

        // Ranking Graph //
        if (!rankingGraph || (rankingGraph && !rankingGraph.success)) {
            hasError = true
        } else {
            const { data }: KlipboxGraphRankingResponse = rankingGraph
            if (data) {
                const result = [] as BarData[]

                // Ordenando do maior para o menor //
                data.sort((a, b) => {
                    const totalB = (b.positivas + b.negativas + b.neutras)
                    const totalA = (a.positivas + a.negativas + a.neutras)
                    if (totalB > totalA) return 1;
                    if (totalB < totalA) return -1;
                    return 0
                })

                // Fazendo o parse dos dados //
                data.forEach((item) => {
                    let monitoringName = formatingMonitoringName(item.monitoramento)
                    result.push(
                        { primary: monitoringName || item.monitoramento, type: "Positivas", value: item.positivas },
                        { primary: monitoringName || item.monitoramento, type: "Neutras", value: item.neutras },
                        { primary: monitoringName || item.monitoramento, type: "Negativas", value: item.negativas }
                    )
                })

                // Setando os resultados //
                setBarDataRanking(result)
            }
        }

        // Top 10 Citações - Positivas //
        if (!top10CitacoesPositivas || (top10CitacoesPositivas && !top10CitacoesPositivas.success)) {
            hasError = true
        } else {
            const { data }: KlipboxGraphTop10Citacoes = top10CitacoesPositivas
            setTop10CitacoesPositivas(data || [])
        }

        // Top 10 Citações - Negativas //
        if (!top10CitacoesNegativas || (top10CitacoesNegativas && !top10CitacoesNegativas.success)) {
            hasError = true
        } else {
            const { data }: KlipboxGraphTop10Citacoes = top10CitacoesNegativas
            setTop10CitacoesNegativas(data || [])
        }

        // Ranking de Portais //
        if (!rankingPortais || (rankingPortais && !rankingPortais.success)) {
            hasError = true
        } else {
            const { data }: KlipboxGraphRankingPortaisResponse = rankingPortais
            if (data) {
                const result = [] as BarData[]

                // Ordenando do maior para o menor //
                data.sort((a, b) => {
                    const totalB = (b.positivas + b.negativas + b.neutras)
                    const totalA = (a.positivas + a.negativas + a.neutras)
                    if (totalB > totalA) return 1;
                    if (totalB < totalA) return -1;
                    return 0
                })

                const _data = data.slice(0, 20)
                _data.forEach((item) => {
                    const _source = item.source
                        .replace("https://", '')
                        .replaceAll("www.", '')
                        .replaceAll("/", '')

                    result.push(
                        { primary: _source || item.source, type: "Positivas", value: item.positivas },
                        { primary: _source || item.source, type: "Neutras", value: item.neutras },
                        { primary: _source || item.source, type: "Negativas", value: item.negativas }
                    )
                })

                // Setando os resultados //
                setRankingPortais(result)
            }
        }

        setLoading(false)
        setPreviewGenerated(true)
        setGenerateInMs(hasError ? -1 : (moment().valueOf() - millisAt))

        AlertsService.notification({
            icon: hasError ? "error" : "success",
            titleText: hasError ?
                "Ocorreu um erro ao obter alguns dados do relatório" :
                "Sucesso! Dados do relatório foram obtidos com sucesso"
        })
    }

    /**
     * @name resetPreview - Remove o preview gerado
     * 
     * @param e 
     */
    const resetPreview = (e: any) => {
        e.preventDefault()
        if (isLoading) {
            return AlertsService.notification({
                icon: "error",
                titleText: "Aguarde a geração do relatório para poder reiniciar o filtro"
            })
        }

        setPieData([])
        setLineData([])
        setBarDataGeral([])
        setBarDataRanking([])
        setPreviewGenerated(false)
        setLoading(false)
        setFiltro({})

        return AlertsService.notification({
            icon: "success",
            titleText: "Filtro reiniciado com sucesso!"
        })
    }

    /**
     * @name updateFilter - Atualiza os valores do filtro 
     * 
     * @param e 
     * 
     * @returns Void
     */
    const updateFilter = (e: any) => {
        e.preventDefault()

        const { target } = e
        const { name, value } = target

        const clientName = target[value].outerText
        if (clientName) {
            filtro.clientName = clientName
        }

        switch (name) {
            case "classification": {
                return filtro.classification = value
            }
            case "clientId": {
                return filtro.clientId = parseFloat(value)
            }
            case "monitoringId": {
                return filtro.monitoringId = parseFloat(value)
            }
            case "title": {
                return filtro.title = value
            }
        }
    }

    /**
     * @name generatePDF - Gera as imagens do PDF
     * 
     * @param e 
     */
    const generatePDF = async (e: any) => {
        const data = {} as {
            startDate: string;
            endDate: string;
            clientId: number;
            capaClienteName?: string;
            clientName?: string;
            tipo: string;
            graphPieTotal: number;
        }

        // Verificando cliente //
        if (filtro.clientId) {
            data.clientId = filtro.clientId
        }

        const _startDate = moment(filtro.date?.startDate || moment().startOf('month'))
        const _endDate = moment(filtro.date?.endDate || moment().endOf('month'))

        // Aplicando a data de inicio //
        data.startDate = _startDate.format('YYYY-MM-DD').toString()
        // Aplicando a data de fim //
        data.endDate = _endDate.format("YYYY-MM-DD").toString()

        const { value: capaClienteName } = await Swal.fire({
            title: "Informações do PDF",
            input: "text",
            inputLabel: "Nome do cliente na capa do PDF",
            inputPlaceholder: "Secretaria de Estado de Comunicação"
        })

        if (capaClienteName) {
            data.capaClienteName = capaClienteName
        }

        // Verificando se existe o nome do cliente //
        if (filtro.clientName) {
            data.clientName = filtro.clientName
        }

        const { value: tipo } = await Swal.fire({
            title: "Informações do PDF",
            input: "text",
            inputLabel: "Nome do JOB",
            inputPlaceholder: "JOB0001-2022"
        })

        if (tipo) {
            data.tipo = tipo
        }

        // Atribuindo o graph pie total //
        const graphPieTotal = pieData.map((v) => v.value).reduce((sum, i) => (sum || 0) + (i || 0))
        if (graphPieTotal) {
            data.graphPieTotal = graphPieTotal
        }

        Swal.fire({
            title: "Gerando seu relatório de repercussão...",
            allowOutsideClick: false,
            didOpen: async () => {
                Swal.showLoading()

                RequestsService.call({
                    requestName: "report",
                    method: "POST",
                    responseType: "blob",
                    baseUrl: environment.URLS.BASE.ARCHIVE,
                    url: environment.URLS.ARCHIVE.PDF.REPERCUSSAO,
                    data
                })
                    // Sucesso //
                    .then((response) => {
                        fileDownload(response.data, 'relatorio-repercussao-.pdf')
                        AlertsService.notification({
                            icon: 'success',
                            title: "Sucesso! PDF de relatório de repercussão gerado com sucesso."
                        })
                    })
                    // Error //
                    .catch((err) => {
                        AlertsService.notification({
                            icon: 'error',
                            title: "Ocorreu um erro ao gerar o seu PDF. Tente novamente gerá-lo."
                        })
                    })
            },
        })
    }

    return (
        <div className="dashboard">
            <MenuComponent name="Repercussão" groupsList={props.groupsList} />

            <div className="dashboard-content">
                {/* Dashboard Header */}
                <KlipboxDashboardHeader />

                {/* Filtros */}
                <KlipboxFiltroRelatorioComponent
                    generatePreview={generatePreview}
                    resetPreview={resetPreview}
                    updateFilter={updateFilter}
                    dateChange={changeDateRange}
                    dateShow={date}
                    generatePDF={generatePDF}
                    previewIsGenerated={isPreviewGenerated}
                    generateInMs={generateInMs}
                />

                {/* Graph */}
                <div className="klipbox-relatorio-graphs">
                    {/* SEM PREVIEW */}
                    {!isLoading && !isPreviewGenerated ?
                        <ComponentInteraction
                            icon={<BiFilterAlt />}
                            text="Selecione um cliente e um período para gerar os dados do relatório" />
                        : <></>}

                    <div className="component-klipbox-relatorio-graphs-minimal">
                        {/* PIE */}
                        <ComponentPieGraph
                            pieData={pieData}
                            color={["#2e75b5", "#a50200", "#fcbd32"]}
                            content={{
                                style: { marginRight: "1vw" },
                                title: "Classificação de notícias",
                                description: "Classificações das notícias coletadas no período selecionado",
                                isLoading: isLoading,
                                hasLoaded: isPreviewGenerated,
                                reference: pieGraphReference
                            }}
                        />

                        {/* LINE */}
                        <ComponentLineGraph
                            lineData={lineData}
                            color={["#2e75b5", "#a50200", "#fcbd32"]}
                            content={{
                                style: { marginTop: "1vw" },
                                title: "Classificação de notícias diária",
                                description: "Classificações das notícias por dia",
                                isLoading: isLoading,
                                hasLoaded: isPreviewGenerated,
                                hasPoint: true
                            }}
                        />
                    </div>

                    {/* BAR TOTAL */}
                    <ComponentBarGraph
                        barData={barDataGeral}
                        content={{
                            style: { marginRight: "1vw" },
                            title: "Classificação total por categoria",
                            description: "Citações separadas por categorias para uma visão geral",
                            isLoading: isLoading,
                            hasLoaded: isPreviewGenerated
                        }}
                        legend={false}
                    />

                    {/* BAR PER CATEGORY */}
                    <ComponentBarGraph
                        barData={barDataRanking}
                        content={{
                            title: "Classificação por categoria",
                            description: "Classificações das categorias ordenadas do maior para o menor",
                            isLoading: isLoading,
                            hasLoaded: isPreviewGenerated
                        }}
                        isClassification={true}
                        legend={{
                            position: "top",
                            itemName: {
                                style: { fill: "white" }
                            }
                        }}
                        color={["#2e75b5", "#fcbd32", "#a50200"]}
                    />

                    {/* TOP 10 CITAÇÕES POSITIVAS */}
                    { !isPreviewGenerated ? <></> : <div className="component-klipbox-relatorio-graphs-top10">
                        <div className="klipbox-graph-title">
                            <h1>Top 10 Citações Positivas</h1>
                        </div>
                        <div className="klipbox-graph-legend">
                            <p>Matérias que foram replicadas positivamente</p>
                        </div>
                        <div className="component-klipbox-relatorio-graph-top10-content">
                            { top10CitacoesPositivas.map((item, index) => (
                                <div className="component-klipbox-relatorio-graph-top10-item component-klipbox-noticia-item-positiva" key={index}>
                                    <div className="component-klipbox-relatorio-graph-top10-item-header">
                                        <span>{moment(item.extracted_date).utc().format("DD/MM/yyyy")}</span>
                                    </div>
                                    
                                    <h1>{item.title}</h1>

                                    <div className="component-klipbox-relatorio-graph-top10-item-footer">
                                        <p>Replicações: <span>{item.duplicates}</span></p>
                                        <a href={`${environment.SERVER.BASEURL.replace("/api", '')}/top10/${item.id}`} target="_blank" rel="noreferrer">Ver replicações</a>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div> }

                    {/* TOP 10 CITAÇÕES NEGATIVAS */}
                    { !isPreviewGenerated ? <></> : <div className="component-klipbox-relatorio-graphs-top10">
                        <div className="klipbox-graph-title">
                            <h1>Top 10 Citações Negativas</h1>
                        </div>
                        <div className="klipbox-graph-legend">
                            <p>Matérias que foram replicadas negativamente</p>
                        </div>
                        <div className="component-klipbox-relatorio-graph-top10-content">
                            { top10CitacoesNegativas.map((item, index) => (
                                <div className="component-klipbox-relatorio-graph-top10-item component-klipbox-noticia-item-negativa" key={index}>
                                    <div className="component-klipbox-relatorio-graph-top10-item-header">
                                        <span>{moment(item.extracted_date).utc().format("DD/MM/yyyy")}</span>
                                    </div>
                                    
                                    <h1>{item.title}</h1>
                                    
                                    <div className="component-klipbox-relatorio-graph-top10-item-footer">
                                        <p>Replicações: <span>{item.duplicates}</span></p>
                                        <a href={`${environment.SERVER.BASEURL.replace("/api", '')}/top10/${item.id}`} target="_blank" rel="noreferrer">Ver replicações</a>
                                    </div>
                                </div>
                            )) }
                        </div>
                    </div> }

                    {/* RANKING PORTAIS */}
                    <ComponentBarGraph
                        barData={rankingPortais}
                        content={{
                            title: "Top 20 - Ranking de portais",
                            description: "Veja os 20 primeiros portais que mais replicaram notícia",
                            isLoading: false,
                            hasLoaded: isPreviewGenerated,
                            style: {
                                marginTop: "2vh",
                                width: "32.1vw"
                            }
                        }}
                        isClassification={true}
                        legend={{
                            position: "top",
                            itemName: {
                                style: { fill: "white" }
                            }
                        }}
                        color={["#2e75b5", "#fcbd32", "#a50200"]}
                    />
                </div>
            </div>
        </div>
    )
}

export default KlipboxRepercussaoRelatorio