import React, { createContext } from "react";
import { environment } from "../common/environments";

// Types //
import { AuthContextState, LoginData, LoginResponse } from "../@types/login.types";

// Service //
import { RequestsService } from "../services/requests.service";
import { StorageService } from "../services/storage.service";
import { AlertsService } from "../services/alerts.service";

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    /**
     * @name signIn - Autentica um usuário
     * 
     * @param loginData 
     * 
     * @returns Promise
     */
    const signIn = (loginData: LoginData): Promise<LoginResponse> => {
        return new Promise((resolve, reject) => {
            const { email, password } = loginData
            const hasValue = (value: any, length: number) => {
                return value.length > length
            }

            // Verifica se existe valores no email e password //
            if (!hasValue(email, 1) || !hasValue(password, 1)) {
                AlertsService.notification({
                    icon: "warning",
                    title: "Por favor, informe corretamente suas credenciais de acesso.",
                })
                return resolve({ error: true })
            }

            RequestsService.call({ requestName: "signIn", method: "POST", url: environment.URLS.USER.LOGIN, data: loginData })
                // Sucesso //
                .then((response) => {
                    const { message, success, token, data } = response.data

                    // Verificando se a autenticação foi bem sucedida //
                    if (success) {
                        // Salvando o token no SessionStorage //
                        StorageService.saveToken(token)
                        // Salvando as informações do usuário //
                        StorageService.saveSession(data)
                    }

                    AlertsService.notification({ icon: success ? "success" : "error", title: message, timer: 2500 })
                        // Término da notificação //
                        .then(() => resolve({ message, success, token, data: data }))
                    
                    resolve({ error: !success })
                })
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name isLogged - Verifica se o usuário está logado
     * 
     * @returns 
     */
    const isLogged = (): boolean => {
        const token = StorageService.getToken()
        return token ? true : false
    }

    /**
     * @name removeLoggedAfterTokenExpired - Remove o token após expiração
     */
    const removeLoggedAfterTokenExpired = (): void => {
        StorageService.removeToken();
    }

    return (
        <AuthContext.Provider value={{ signIn, isLogged, removeLoggedAfterTokenExpired }}>
            {children}
        </AuthContext.Provider>
    )
}

function useAuth(): AuthContextState {
    return React.useContext(AuthContext)
}

export { AuthProvider, useAuth }