import HTMLReactParser from "html-react-parser"

// CSS //
import "./dropdown.css"

const ComponentInfoDropdown = (props: { text?: string }) => {
    return (
        <div className="component-info-dropdown">
            <p>{HTMLReactParser(props.text || "Algo deveria ser informado aqui.")}</p>
        </div>
    )
}

export default ComponentInfoDropdown