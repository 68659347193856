import { InfoProps } from "../../@types/component.types";
import ComponentDropdown from "../dropdown";

// CSS //
import "./info.css"

const ComponentInfo = (props: InfoProps) => {
    return (
        <div className="component-info" style={props.style}>
            {/* ÍCONE */}
            {props.icon}

            {/* DROPDOWN */}
            <ComponentDropdown isInfo={true} infoText={props.text} />
        </div>
    )
}

export default ComponentInfo;