import React from "react"
import moment from "moment"

// Types //
import { LineGraph } from "../../../@types/graphs.types"
// Chart //
import { Line } from '@ant-design/plots'
// CSS //
import './line.css'
// Component //
import ComponentLineSkeleton from "./line-skeleton"

const ComponentLineGraph = (props: LineGraph) => {
    // Verifica se está carregando //
    if (props.content?.isLoading) {
        return <ComponentLineSkeleton />
    }

    // Conteúdo não carregado //
    if (!props.content?.hasLoaded) {
        return <></>
    }

    return (
        <div className="component-line" style={props.content?.style}>
            <div className="klipbox-graph-title">
                <h1>{props.content?.title || "Insira um título corretamente."}</h1>
            </div>
            <div className="klipbox-graph-legend">
                <p>{props.content?.description || "Insira uma descrição corretamente."}</p>
            </div>

            <div className="component-line-graph" ref={props.content.reference}>
                <Line
                    data={props.lineData || []}
                    xField="date"
                    yField="value"
                    seriesField="classification"
                    xAxis={{
                        label: {
                            style: { fill: "white", fontFamily: "Poppins" },
                            formatter: (v) => {
                                const d = v.split("/")
                                return moment(`${d[1]}/${d[0]}/${d[2]}`).format("DD/MM")
                            }
                        }
                    }}
                    yAxis={{
                        label: {
                            style: { fill: "white", fontFamily: "Poppins" },
                            formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                        }
                    }}
                    color={props.color}
                    legend={{
                        position: "top",
                        itemName: {
                            style: { fill: "white", fontFamily: "Poppins" }
                        }
                    }}
                    interactions={[
                        { type: props.content.hasPoint ? "marker-active" : "" }
                    ]}
                    className="component-line-canvas"
                    smooth={true}
                    label={{
                        style: { fill: props.content.hasPoint ? "white" : "transparent" }
                    }}
                    point={{
                        size: props.content.hasPoint ? 3 : 0,
                        shape: "point",
                        style: {
                            fill: "white",
                            stroke: "#5B8FF9",
                            lineWidth: 2
                        }
                    }}
                />
            </div>
        </div>
    )

}

export default ComponentLineGraph