import { Line } from "@ant-design/plots"
import moment from "moment"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { LineData } from "../../../../@types/graphs.data.types"
import { KlipboxGraphDailyResponse } from "../../../../@types/klipbox.response.types"
import { environment } from "../../../../common/environments"
import { useKlipbox } from "../../../../context/klipbox.context"
import { AlertsService } from "../../../../services/alerts.service"

// CSS //
import "./private.css"

const ComponentGraphLine = () => {

    const { fetchGraph } = useKlipbox()

    const [lineData, setLineData] = useState({} as LineData[])
    const [isLoading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(window.location.search)
    const accessToken = queryParams.get("accessToken")
    const token = queryParams.get("token")
    const startDate = queryParams.get("startDate")
    const endDate = queryParams.get("endDate")
    const clientId = queryParams.get("clientId")

    useEffect(() => {
        const params = {
            clientId: clientId || 2,
            startDate: startDate || moment().startOf('month').format("YYYY-MM-DD"),
            endDate: endDate || moment().endOf('month').format("YYYY-MM-DD"),
            token: accessToken
        }

        const getData = setTimeout(async () => {
            // Verificando se existe acesso //
            if (!token || token !== environment.URLS.GRAPH_TOKEN) {
                return
            }

            // PieData //
            const response: KlipboxGraphDailyResponse = await fetchGraph(environment.URLS.KLIPBOX.GRAPH.DAILY, params)
            if (response) {
                const { success, data } = response
                if (success && data) {
                    const result = [] as LineData[]

                    // Fazendo o parse dos dados //
                    data.forEach((item) => {
                        result.push(
                            { date: item.date + `/${moment().format("YYYY")}`, classification: "Positivas", value: item.positivas },
                            { date: item.date + `/${moment().format("YYYY")}`, classification: "Negativas", value: item.negativas },
                            { date: item.date + `/${moment().format("YYYY")}`, classification: "Neutras", value: item.neutras },
                        )
                    })

                    // Setando os resultados //
                    setLineData(result)
                    setLoading(false)
                }
            }
        })

        return () => clearTimeout(getData)
    }, [])

    if (!token || token !== environment.URLS.GRAPH_TOKEN) {
        AlertsService.notification({ icon: "error", title: "Você não tem permissões suficientes para acessar essa rota." })
        return <Navigate to={environment.ROUTES.DASHBOARD} replace />
    }

    if (isLoading) {
        return <></>
    }

    return (
        <div className="private-graph">
            <Line
                data={lineData}
                xField="date"
                yField="value"
                seriesField="classification"
                xAxis={{
                    label: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 26 },
                        formatter: (v: any) => {
                            const d = v.split("/")
                            return moment(`${d[1]}/${d[0]}/${d[2]}`).format("DD/MM")
                        }
                    }
                }}
                yAxis={{
                    label: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 30, textAlign: "start" },
                        formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
                    }
                }}
                color={["#2e75b5", "#a50200", "#fcbd32"]}
                legend={{
                    position: "top",
                    itemName: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 28 }
                    }
                }}
                interactions={[
                    { type: "marker-active" }
                ]}
                className="component-line-canvas"
                smooth={true}
                label={{
                    style: { fill: "black", fontSize: 19, fontFamily: "Poppins" }
                }}
                point={{
                    size: 3,
                    shape: "point",
                    style: {
                        fill: "white",
                        stroke: "#5B8FF9",
                        lineWidth: 2.5
                    }
                }}
            />
        </div>
    )
}

export default ComponentGraphLine