import React, { createContext } from "react";
import { environment } from "../common/environments";

// Types //
import { GraphInterface, KlipboxDeleteResponse, KlipboxMonitoringsResponse, KlipboxNewsResponse, KlipboxUpdateResponse } from "../@types/klipbox.response.types";

// Service //
import { RequestsService } from "../services/requests.service";
import { StorageService } from "../services/storage.service";
import { KlipboxContextState, KlipboxNoticia, KlipboxUpdate } from "../@types/klipbox.types";
import { ClientResponse } from "../@types/client.types";
import { UsersInterface, UsersResponse } from "../@types/users.types";
import { GroupsResponse } from "../@types/groups.types";

const KlipboxContext = createContext<KlipboxContextState>({} as KlipboxContextState);

const KlipboxProvider = ({ children }: { children: React.ReactNode }) => {

    /**
     * @name fetchNews - Obtém todas as notícias do Klipbox
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchNews = (params?: {}): Promise<KlipboxNewsResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ 
                requestName: "fetchNews", 
                method: "GET", 
                baseUrl: environment.URLS.BASE.NEWS, 
                url: environment.URLS.KLIPBOX.LIST, 
                params 
            })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name fetchNew - Obtém uma notícia do Klipbox pelo ID
     * 
     * @param id 
     * 
     * @returns Promise
     */
    const fetchNew = (id: number): Promise<KlipboxNoticia> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "fetchNew", method: "GET", url: environment.URLS.KLIPBOX.GET + id, })
                // Sucesso //
                .then((request) => resolve(request.data.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name updateNew - Atualização de notícias
     * 
     * @param id 
     * @param params 
     * 
     * @returns Promise
     */
    const updateNew = (id: number, data?: KlipboxUpdate): Promise<KlipboxUpdateResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "updateNew", method: "PUT", url: environment.URLS.KLIPBOX.UPDATE + id, data })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name deleteNew - Deleta uma notícia
     * 
     * @param id 
     * 
     * @returns Promise
     */
    const deleteNew = (id: number): Promise<KlipboxDeleteResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "deleteNew", method: "DELETE", url: environment.URLS.KLIPBOX.DEL + id })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name fetchMonitorings - Obtém todos os monitoramentos do Klipbox
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchMonitorings = (params?: {}): Promise<KlipboxMonitoringsResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "fetchMonitorings", method: "GET", url: environment.URLS.KLIPBOX.MONITORING.LIST, params })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name fetchClients - Obtém todos os clientes
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchClients = (params?: {}): Promise<ClientResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "fetchClients", method: "GET", url: environment.URLS.CLIENTS.LIST, params })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name fetchGroups - Obtém todos os grupos
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchGroups = (params?: {}): Promise<GroupsResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "fetchGroups", method: "GET", url: environment.URLS.GROUPS.LIST, params })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name fetchGraph - Obtém os dados do gráfico
     * 
     * @param url 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchGraph = (url: string, params?: {}): Promise<GraphInterface> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "fetchGraph", method: "GET", baseUrl: environment.URLS.BASE.NEWS, url, params })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name fetchUsers - Obtém os dados do gráfico
     * 
     * @param params 
     * 
     * @returns Promise
     */
    const fetchUsers = (params?: {}): Promise<UsersResponse> => {
        return new Promise((resolve, reject) => {
            RequestsService.call({ requestName: "fetchUsers", method: "GET", url: environment.URLS.USER.LIST, params })
                // Sucesso //
                .then((request) => resolve(request.data))
                // Error //
                .catch(reject)
        })
    }

    /**
     * @name isFetching - Verifica se a requisição para obter novas notícias está sendo feita
     * 
     * @returns Boolean
     */
    const isFetching = (): boolean => {
        return StorageService.isFetching()
    }

    /**
     * @name setFetching - Atualiza para true ou false 
     * 
     * @param value 
     * 
     * @returns Void
     */
    const setFetching = (value: boolean) => {
        return StorageService.setFetching(value)
    }

    return (
        <KlipboxContext.Provider value={{
            fetchNews,
            fetchNew,
            updateNew,
            deleteNew,
            fetchMonitorings,
            fetchClients,
            fetchGroups,
            isFetching,
            setFetching,
            fetchGraph,
            fetchUsers,
        }}>
            {children}
        </KlipboxContext.Provider>
    )
}

function useKlipbox(): KlipboxContextState {
    return React.useContext(KlipboxContext)
}

export { KlipboxProvider, useKlipbox }