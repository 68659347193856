import { useEffect, useState } from "react";

// Types //
import { ClientInterface } from "../../@types/client.types";
import { FiltroProps } from "../../@types/component.types";
import { MonitoringsInterface } from "../../@types/monitorings.types";
import { getClassifications } from "../../common/utilitaries";

// Context //
import { useKlipbox } from "../../context/klipbox.context";
import { AlertsService } from "../../services/alerts.service";

// Component //
import ButtonComponent from "../button";
import FiltroItemComponent from "../filtro-item";

// CSS //
import "./klipbox-filtro.css"

const KlipboxFiltroComponent = (props: FiltroProps) => {

    const { fetchMonitorings, fetchClients } = useKlipbox()

    const defaultClient = { id: -1, name: "Clientes", sigla: "CLI", avatar: "", capa: "", created_at: new Date(), updated_at: new Date() }
    const defaultMonitoring = { id: -1, monitoring_id: "-1", name: "Monitoramento", monitoring_classification: "", required_terms: "", terms: "", created_at: new Date(), updated_at: new Date() }

    const [clients, setClients] = useState({ isFetched: false, data: [{
        id: -1,
        name: "Clientes",
        sigla: "CLI"
    }] } as ClientInterface)
    const [monitorings, setMonitorings] = useState({ isFetched: false, data: [{
        id: -1,
        monitoring_id: "-1",
        name: "Monitoramento"
    }] } as MonitoringsInterface)

    const updateMonitoringsAfterSelectClient = (event: any) => {
        event.preventDefault()
        props.onChange(event)

        const { target } = event
        const { value } = target

        var clientId = undefined;

        if (value !== '-1') {
            const _client = clients.data.filter((c) => c.id === Number(value))
            if (_client.length > 0) {
                clientId = _client[0].id
            }
        }

        try {
            monitorings.isFetched = false;
            monitorings.data = [defaultMonitoring]
        } finally {
            fetchListOfMonitorings({ "all": true, clientId })
        }
    }

    const fetchListOfMonitorings = (params: { "all": boolean, "clientId"?: number }) => {
        // Verificando se os monitoramentos não foram buscados //
        if (!monitorings.isFetched) {
            // Obtendo os monitoramentos //
            fetchMonitorings(params)
                // Sucesso //
                .then((monitorings) => {
                    // Obtendo a lista //
                    const _monitorings = [defaultMonitoring]
                    monitorings.data.forEach((value) => _monitorings.push(value))
                    // Aplicando //
                    setMonitorings({ data: _monitorings, isFetched: true })
                })
                // Error //
                .catch((e) => {
                    console.log(e)
                    AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar os monitoramentos do Klipbox cadastrados." })
                })
        }
    }

    const fetchClientsAndMonitorings = async (params: { "all": boolean, "clientId"?: number }) => {
        if (!clients.isFetched && !monitorings.isFetched) {
            const [ clients, monitorings ] = await Promise.all([
                fetchClients(params),
                fetchMonitorings(params)
            ])

            // Obtendo a lista //
            const _clients = [defaultClient]
            clients.data.forEach((value) => _clients.push(value))
            // Setando //
            setClients({ data: _clients, isFetched: true })

            // Obtendo a lista //
            const _monitorings = [defaultMonitoring]
            monitorings.data.forEach((value) => _monitorings.push(value))
            // Aplicando //
            setMonitorings({ data: _monitorings, isFetched: true })
        }
    }

    useEffect(() => {
        const fetch = setTimeout(() => fetchClientsAndMonitorings({ "all": true }), 0)
        return () => clearTimeout(fetch)
    }, [])

    return (
        <div className="klipbox-filtro">
            {/* Classificação */}
            <FiltroItemComponent
                type="select"
                id="classification"
                name="classification"
                title="Classificação"
                onChange={props.onChange}
                childrens={
                    getClassifications().map((_class, index) => (
                        <option key={index} value={_class.id}>{_class.name}</option>
                    ))
                }
            />

            {/* Cliente */}
            <FiltroItemComponent
                type="select"
                id="clientId"
                name="clientId"
                title="cliente"
                onChange={updateMonitoringsAfterSelectClient}
                childrens={
                    clients.data.map((value, index) => (
                        <option key={index} value={value.id}>{value.name}</option>
                    ))
                }
                style={{ width: "225px" }}
            />

            {/* Monitoramento */}
            <FiltroItemComponent
                type="select"
                id="monitoringId"
                name="monitoringId"
                title="monitoramento"
                onChange={props.onChange}
                childrens={
                    monitorings.data.map((value, index) => (
                        <option key={index} value={value.id}>{value.name}</option>
                    ))
                }
                style={{ width: "285px" }}
            />

            {/* Título */}
            <FiltroItemComponent
                type="input"
                id="title"
                name="title"
                title="title"
                placeholder="Título"
                onChange={props.onChange}
            />

            {/* Data */}
            <FiltroItemComponent
                type="date"
                id="date"
                name="date"
                dateChange={props.dateChange}
                dateShow={props.dateShow}
            />

            {/* Button */}
            <ButtonComponent
                text="Buscar"
                type="submit"
                onClick={props.onClick}
                class="klipbox-filtro-button"
            />

            {/* Button reset */}
            <ButtonComponent
                text="Resetar"
                type="reset"
                onClick={props.onReset}
                class="klipbox-filtro-button"
            />
        </div>
    )
}

export default KlipboxFiltroComponent;