// CSS //
import { DropDownProps } from "../../@types/component.types";
import ComponentInfoDropdown from "../info/dropdown";
import NotificationComponent from "../notification";

// CSS //
import "./dropdown.css";

const ComponentDropdown = (props: DropDownProps) => {
    const className = "component-dropdown" + (props.isNotification || props.isInfo ? " component-dropdown-build" : "")

    const buildComponent = () => {
        if (props.isNotification)
            return ( <NotificationComponent /> )
        if (props.isInfo)
            return ( <ComponentInfoDropdown text={props.infoText} /> )
    }

    return (
        <div className={className}>
            {/* Component */}
            {buildComponent()}
        </div>
    )
}

export default ComponentDropdown;