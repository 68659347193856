import React from "react"

// Chart //
import { Bar } from '@ant-design/plots'

// CSS //
import './bar.css'

// Types //
import { BarGraph } from "../../../@types/graphs.types"

// Component //
import ComponentBarSkeleton from "./skeleton"

const ComponentBarGraph = (props: BarGraph) => {
    // Verifica se está carregando //
    if (props.content?.isLoading) {
        return <ComponentBarSkeleton />
    }

    // Conteúdo não carregado //
    if (!props.content?.hasLoaded) {
        return <></>
    }

    return (
        <div className="component-bar" style={props.content?.style}>
            <div className="klipbox-graph-title">
                <h1>{props.content?.title || "Insira um título corretamente."}</h1>
            </div>
            <div className="klipbox-graph-legend">
                <p>{props.content?.description || "Insira uma descrição corretamente."}</p>
            </div>

            <div className="component-bar-graph" ref={props.content.reference}>
                <Bar
                    data={props.barData}
                    isStack={true}
                    xField="value"
                    yField="primary"
                    seriesField="type"
                    legend={props.legend}
                    className="component-bar-canvas"
                    color={props.color}
                    label={{
                        position: "middle",
                        style: { fontFamilly: "Poppins" },
                        layout: [
                            { "type": "adjust-color" },
                            { "type": "interval-hide-overlap" },
                            { "type": "interval-adjust-position" },
                        ]
                    }}
                    yAxis={{
                        label: {
                            style: { fill: "white", fontFamily: "Poppins" },
                            formatter: (v) => v.toUpperCase()
                        }
                    }}
                    xAxis={{
                        label: {
                            style: { fill: "white", fontFamily: "Poppins" }
                        }
                    }}
                />
            </div>
        </div>
    )

}

export default ComponentBarGraph