import React from "react"
import moment from "moment";
import { FiltroItemProps } from "../../@types/component.types";

// Date //
import DateRangePicker from '@wojtekmaj/react-daterange-picker'

// CSS //
import "./filtro-item.css"

const FiltroItemComponent = (props: FiltroItemProps) => {
    const buildType = () => {
        switch (props.type) {
            case "select": {
                return (
                    <select className="component-filtro-select component-filtro-style"
                        id={props.id}
                        name={props.name}
                        onChange={props.onChange}
                        defaultValue={props.value}
                        style={props.style}
                    >
                        {props.childrens}
                    </select>
                )
            }
            case "input": {
                return (
                    <input className="component-filtro-input component-filtro-style"
                        name={props.name}
                        id={props.id}
                        placeholder={props.placeholder}
                        defaultValue={props.value}
                        type="input"
                        onChange={props.onChange}
                        readOnly={props.readOnly}
                    />
                )
            }
            case "text": {
                return (
                    <input className="component-filtro-input component-filtro-style"
                        name={props.name}
                        id={props.id}
                        placeholder={props.placeholder}
                        type="text"
                        onChange={props.onChange}
                        readOnly={props.readOnly}
                    />
                )
            }
            case "date": {
                const parseDateToString = () => {
                    const startDate = moment().startOf("month").toDate()
                    const endDate = moment().endOf("month").toDate()
                    return [props.dateShow?.startDate || startDate, props.dateShow?.endDate || endDate]
                }

                return (
                    <DateRangePicker
                        onChange={props.dateChange}
                        value={parseDateToString()}
                        className="component-filtro-style component-filtro-date"
                        format="dd/MM/y"
                    />
                )
            }
        }
    }

    const buildIcon = () => {
        if (!props.hasIcon)
            return;

        let iconAlign = ""
        switch (props.iconAlign) {
            case "left":
                iconAlign = " component-filtro-icon-left"
                break;
            case "right":
                iconAlign = " component-filtro-icon-right"
                break;
        }

        const className = "component-filtro-item-icon" + (iconAlign !== "" ? " " + iconAlign : "")
        return (
            <div className={className}>
                <span>{props.icon}</span>
            </div>
        )
    }

    return (
        <div className="component-filtro-item">
            {/* Build icon */}
            {props.iconAlign === "left" ? buildIcon() : ""}

            {/* Build type */}
            {buildType()}

            {/* Build icon */}
            {props.iconAlign === "right" ? buildIcon() : ""}
        </div>
    )
}

export default FiltroItemComponent;