import { BiCog, BiHome, BiNetworkChart } from 'react-icons/bi'
import { environment } from "../../common/environments";

// Types //
import { MenuProps } from '../../@types/component.types';

// Component //
import MenuItemComponent from '../menu-item';

// CSS //
import "./menu.css"

// Image //
import logo from '../../files/images/marca-nova.png'

// Context //
import { MenuPrivateProvider } from '../../context/menu.private.context';

/**
 * @name MenuComponent - Component do menu
 * 
 * @returns Component
 */
const MenuComponent = (props: MenuProps) => {
    return (
        <div className="menu">

            {/* MENU HEADER */}
            <div className="menu-header">
                <img alt="logo" src={logo} />
            </div>

            {/* MENU CONTENT */}
            <div className="menu-content">

                {/* Dashboard */}
                <MenuItemComponent
                    name='Dashboard'
                    hasIcon={true}
                    icon={<BiHome />}
                    redirectTo={environment.ROUTES.DASHBOARD}
                    route={props.name}
                />

                <MenuPrivateProvider group={environment.GROUPS.MONITORAMENTO} groupsList={props.groupsList}>
                    {/* Repercussão */}
                    <MenuItemComponent
                        name='Repercussão'
                        hasIcon={true}
                        icon={<BiNetworkChart />}
                        redirectTo={environment.ROUTES.KLIPBOX.REPERCUSSAO}
                        route={props.name}
                    />
                </MenuPrivateProvider>

                <MenuPrivateProvider group={environment.GROUPS.ADMIN} groupsList={props.groupsList}>
                    {/* Configurações */}
                    <MenuItemComponent
                        name='Configurações'
                        hasIcon={true}
                        icon={<BiCog />}
                        redirectTo={environment.ROUTES.CONFIGURACOES.USUARIOS}
                        route={props.name}
                    />
                </MenuPrivateProvider>

            </div>

            {/* MENU FOOTER */}
            <div className="menu-footer">
                <p>Distribuído por <br />
                    <a href='https://imarketing.digital/monitoramento/' target="_blank" rel="noreferrer">iMarketing Agência Digital</a>
                </p>
                <span>Versão: {environment.VERSION}</span>
            </div>
        </div>
    )
}

export default MenuComponent