import React from "react";
import { Navigate } from "react-router-dom";
import { RedirectRouteState } from "../../@types/route.types";
import { useAuth } from "../../context/auth.context";

const LoginRedirectRoute: React.FC<RedirectRouteState> = ({ route, children }) => {

    const { isLogged } = useAuth()

    const redirectChildren = () => {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }

    return isLogged() ? <Navigate to={route} replace /> : redirectChildren()
}

export { LoginRedirectRoute }