import moment from "moment";
import { KlipboxNoticia } from "../@types/klipbox.types";

export const formatClientNameFromKlipboxNews = (noticia: KlipboxNoticia) => {
    try {
        return noticia.client.name
    } catch {
        return "Não encontrado"
    }
}

export const formatMonitoringNameFromKlipboxNews = (noticia: KlipboxNoticia) => {
    try {
        return noticia.client.name
    } catch {
        return "Não encontrado"
    }
}

export const formatingMonitoringName = (monitoramento: String) => {
    try {
        let monitoringName = undefined
        if (monitoramento.indexOf("GOV") !== -1) {
            monitoringName = monitoramento.split(" - ")[1]
            if (monitoringName.indexOf("G1") !== -1) {
                monitoringName = "Governo do Amazonas"
            }
        }

        return monitoringName
    } catch {
        return undefined
    }
}

interface ConvertMsToTimeInterface {
    isSeconds?: boolean;
    isMinutes?: boolean;
    isHours?: boolean;
}

export const convertMsToTime = (milliseconds: number, props?: ConvertMsToTimeInterface) => {
    try {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;
        hours = hours % 24;
        
        return props?.isHours ? hours : props?.isMinutes ? minutes : props?.isSeconds ? seconds : milliseconds;
    } catch {
        return milliseconds
    }
}

export const formatDateByDate = (date: Date) => {
    try {
        return moment(date).utc().format("DD/MM/yyyy");
    } catch {
        return moment().utc().format("DD/MM/yyyy")
    }
}

export const formatDate = (noticia: KlipboxNoticia) => {
    try {
        const _date = noticia.extracted_date ? new Date(noticia.extracted_date.toString().split(" ")[0]) : moment().format("yyyy-MM-DD")
        return moment(_date).utc().format("DD/MM/yyyy");
    } catch {
        return moment().utc().format("DD/MM/yyyy")
    }
}

export const getClassifications = (withoutAnalyzed?: boolean) => {
    var classifications = [
        { "id": undefined, "name": "Classificação" },
        { "id": "Não analisado", "name": "Aguardando Análise" },
        withoutAnalyzed ? {} : { "id": "Noticias analisadas", "name": "Noticias analisadas" },
        { "id": "Positiva", "name": "Positiva" },
        { "id": "Negativa", "name": "Negativa" },
        { "id": "Neutra", "name": "Neutra" },
    ]

    return classifications;
}

export const formatNewsDescription = (noticia: KlipboxNoticia) => {
    try {
        const listOfHtmlsBroken = [
            "<span>", "<span;>"
        ]
        const replaceList = [
            noticia.monitoring_name,
            noticia.client.name
        ];
    
        const monitoring = noticia.monitoring
        if (monitoring) {
            try {
                let monitoringTerms = JSON.parse("[" + monitoring.terms + "]")[0]
                if (monitoringTerms) {
                    monitoringTerms.forEach((value: string) => replaceList.push(value))
                }
            } catch (e) {
                let monitoringTerms = monitoring.terms.split(',')
                if (monitoringTerms) {
                    monitoringTerms.forEach((value: string) => replaceList.push(value))
                }
            }
        }
    
        let text = noticia.extend.description
        for (let i = 0; i < listOfHtmlsBroken.length; i++) {
            text = text.replace(listOfHtmlsBroken[i], '')
        }
        
        let otherText = text
        let stringToReplace = [] as any
    
        replaceList.forEach((value) => {
            try {
                const regex = RegExp(value, 'gi')
                const findWord = regex.test(otherText)
                const matchWord = (otherText.match(regex) || [])[0] || ""
    
                // Não achou alguma palavra //
                if (!findWord) return
    
                stringToReplace.push({ name: matchWord, regex })
            } catch (e) {
                // Ocorreu um erro e fazer o tratamento aqui //
            }
        })
    
        let order = stringToReplace.sort((a: any, b: any) => {
            if (b.name.length > a.name.length) return 1
            if (b.name.length < a.name.length) return -1
            return 0
        })
    
        order.forEach((o: any) => {
            const { name, regex } = o
            text = text.replace(regex, '<mark>' + name + '</mark>')
        })
    
        return text
    } catch (e) {
        console.error("Ocorreu um erro ao formatar a notícia", e)
        return noticia.extend.description
    }
}

export const formatPhoneNumber = (phone?: string) => {
    try {
        //Filter only numbers from the input
        let cleaned = ('' + phone).replace(/\D/g, '');
        
        //Check if the input is of correct
        if (cleaned.length === 10) {
            return cleaned.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3')
        } else {
            return cleaned.replace(/^(\d{2})(\d{1})(\d{4})(\d{4})$/, '($1) $2 $3-$4')
        }
    } catch {
        return phone
    }
}

export const existsValueInArray = (value: string, array: string[]) => {
    try {
        var exists = false
        for (var i = 0; i < array.length; i++) {
            const _value = array[i]
            if (String(value) === String(_value)) {
                exists = true;
                break;
            }
        }
        
        return exists
    } catch  {
        return false
    }
}