import React from "react"

// Component //
import ComponentPieSkeleton from "./pie-skeleton"
// Types //
import { PieGraph } from "../../../@types/graphs.types"
// Chart //
import { Pie } from '@ant-design/plots'
// CSS //
import './pie.css'

const ComponentPieGraph = (props: PieGraph) => {
    // Verifica se está carregando os dados //
    if (props.content?.isLoading) {
        // SKELETON LOADING //
        return <ComponentPieSkeleton />
    }

    // Verifica se existe dados //
    if (!props.content?.hasLoaded) {
        return <></>
    }

    return (
        <div className="component-pie" style={props.content?.style}>
            <div className="klipbox-graph-title">
                <h1>{props.content?.title || "Insira um título corretamente."}</h1>
            </div>
            <div className="klipbox-graph-legend">
                <p>{props.content?.description || "Insira uma descrição corretamente."}</p>
            </div>

            <div className="component-pie-graph">
                <Pie
                    ref={props.content.reference}
                    appendPadding={10}
                    data={props.pieData}
                    angleField="value"
                    colorField="type"
                    radius={0.8}
                    legend={{
                        position: "bottom",
                        itemName: { style: { fill: "white", fontSize: 15 } }
                    }}
                    label={{
                        type: 'spider',
                        labelHeight: 28,
                        content: '{name}\n{percentage}',
                        style: { fill: "white", fontSize: 15 }
                    }}
                    theme={{
                        styleSheet: { fontFamily: "Poppins" }
                    }}
                    interactions={[
                        { type: 'pie-legend-active' },
                        { type: 'element-active' },
                    ]}
                    color={props.color}
                    className="component-pie-canvas"
                />
            </div>
        </div>
    )

}

export default ComponentPieGraph