// CSS //
import "./skeleton.css"

const TableSkeletonComponent = () => {
    return (
        <div className="component-table">
            {/* HEADER */}
            <div className="component-table-header-skeleton">
                <h4 className="component-table-skeleton-body component-table-skeleton-loading">Título 1</h4>
                <h4 className="component-table-skeleton-body component-table-skeleton-loading">Título 2</h4>
                <h4 className="component-table-skeleton-body component-table-skeleton-loading">Título 3</h4>
                <h4 className="component-table-skeleton-body component-table-skeleton-loading">Título 4</h4>
                <h4 className="component-table-skeleton-body component-table-skeleton-loading">Título 5</h4>
                <h4 className="component-table-skeleton-body component-table-skeleton-loading">Título 6</h4>
            </div>

            {/* BODY */}
            {
                Array(10).fill([]).map((value, index) => (
                    <div key={index} className="component-table-body-item-skeleton">
                        <div className="component-table-body-item-division-skeleton">
                            <h4 className="component-table-skeleton-body component-table-skeleton-loading">Item 1. Deve aparecer algo aqui.</h4>
                        </div>
                        <div className="component-table-body-item-division-skeleton">
                            <h4 className="component-table-skeleton-body component-table-skeleton-loading">Item 2. Deve aparecer algo aqui.</h4>
                        </div>
                        <div className="component-table-body-item-division-skeleton">
                            <h4 className="component-table-skeleton-body component-table-skeleton-loading">Item 3. Deve aparecer algo aqui.</h4>
                        </div>
                        <div className="component-table-body-item-division-skeleton">
                            <h4 className="component-table-skeleton-body component-table-skeleton-loading">Item 4. Deve aparecer algo aqui.</h4>
                        </div>
                        <div className="component-table-body-item-division-skeleton">
                            <h4 className="component-table-skeleton-body component-table-skeleton-loading">Item 5. Deve aparecer algo aqui.</h4>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default TableSkeletonComponent