import React from "react"
import { useState } from "react"

// Component //
import KlipboxFiltroComponent from "../../component/klipbox-filtro"
import KlipboxNoticiasComponent from "../../component/klipbox-noticias"
import MenuComponent from "../../component/menu"
import KlipboxDashboardHeader from "./header"

// Types //
import { DateRangeProps, FiltroStateProps, MenuPrivateProps } from "../../@types/component.types"
import { useKlipbox } from "../../context/klipbox.context"
import { AlertsService } from "../../services/alerts.service"
import { KlipboxFetch, KlipboxNoticia, KlipboxStatics } from "../../@types/klipbox.types"
import { KlipboxNewsResponse } from "../../@types/klipbox.response.types"
import moment from "moment"

const KlipboxRepercussao = (props: MenuPrivateProps) => {

    const { fetchNews } = useKlipbox()

    const [isLoading, setLoading] = useState(true)

    var [noticiaId, setNoticiaId] = useState(-1)
    const [date, setDate] = useState([] as DateRangeProps)
    const [filtro] = useState({ classification: undefined, clientId: -1, monitoringId: -1, title: undefined, } as FiltroStateProps)
    const [statics, setStatics] = useState({ isFetching: true, count: 0, page: 1, total: 0, totalPage: 1 } as KlipboxStatics)
    const [noticias, setNoticias] = useState([] as KlipboxNoticia[])
    const [scrollParameters, setScrollParameters] = useState({} as KlipboxFetch)

    /**
     * @name requestFilter - Busca as notícias com base no filtro
     */
    const requestFilter = (event: any) => {
        event.preventDefault()
        // Verificando se já está sendo recarregado //
        if (isLoading) {
            return
        }

        filtro.date = date
        if (filtro.classification === undefined && filtro.clientId === -1 && Object.keys(filtro.date).length < 1 && filtro.monitoringId === -1 && filtro.title === undefined) {
            return AlertsService.notification({
                icon: "warning",
                title: "Por favor, selecione um filtro para poder fazer uma busca avançada"
            })
        }

        // params //
        const params = {
            classification: filtro.classification,
            clientId: filtro.clientId === -1 ? undefined : filtro.clientId,
            monitoringId: filtro.monitoringId === -1 ? undefined : filtro.monitoringId,
            title: filtro.title,
            startDate: filtro.date.startDate !== undefined ? moment(filtro.date.startDate).format("YYYY-MM-DD") : undefined,
            endDate: filtro.date.endDate !== undefined ? moment(filtro.date.endDate).format("YYYY-MM-DD") : undefined
        }

        // Atualiza //
        updateValues({ params: params })
        setScrollParameters(params)
    }

    /**
     * @name updateValues - Atualiza os valores com base em parâmetros
     * 
     * @param params 
     */
    const updateValues = (data: {
        params?: {};
        isReset?: boolean;
        deleteNew?: boolean;
        newId?: number;
        newsUpdate?: boolean;
        newNews?: KlipboxNoticia[];
    }) => {
        // Verifica se está deletando uma notícia //
        if (data.deleteNew) {
            const { newId } = data
            
            statics.count = (statics.count - 1)
            statics.total = (statics.total - 1)

            const _noticias = noticias.filter((n) => n.id !== newId)
            setNoticias(_noticias)
            setNoticiaId(-1)
            return;
        }

        // Verifica se houve atualização nas notícias //
        if (data.newsUpdate) {
            setNoticias(data.newNews || [])
            return;
        }

        // Atualizando valores //
        setLoading(true)
        setStatics({ count: 0, isFetched: false, isFetching: true, page: 1, total: 0, totalPage: 1 })
        setNoticiaId(-1)

        // FetchNews //
        const timeout = setTimeout(() => {
            const _fetch = data.isReset ? {} : data.deleteNew ? scrollParameters : data.params
            // Fetch news //
            fetchNews(_fetch)
                // Sucesso //
                .then((response) => {
                    const { data, page, total, totalPage, newNews } = response

                    setLoading(false)
                    setNoticias(data.length < 1 ? [] : data)
                    setStatics({
                        count: data.length,
                        page,
                        total,
                        totalPage,
                        newNews,
                        isFetching: false,
                        isFetched: true
                    })
                })
                // Error //
                .catch((err) => {
                    console.log(err)
                })
        }, 1000)

        return () => clearTimeout(timeout)
    }

    /**
     * @name changeFiltro - Modifica um valor do filtro 
     * 
     * @param event 
     * 
     * @returns Void 
     */
    const changeFiltro = (event: any) => {
        event.preventDefault()

        const { target } = event
        const { name, value } = target

        switch (name) {
            case "classification": {
                filtro.classification = value === "Classificação" ? undefined : value
                break;
            }
            case "clientId": {
                filtro.clientId = parseFloat(value)
                break;
            }
            case "monitoringId": {
                filtro.monitoringId = parseFloat(value)
                break;
            }
            case "title": {
                filtro.title = value
                break;
            }
        }
    }

    /**
     * @name changeDataRange - Modifica a range de data do calendário
     * 
     * @param value 
     * 
     * @returns Void
     */
    const changeDataRange = (value: string | Date) => {
        if (!value) {
            setDate({})
        } else {
            const split = value.toString().split(",")
            setDate({ startDate: new Date(split[0]), endDate: new Date(split[1]) })
        }
    }

    /**
     * @name changeStatics - Atualiza as estatísticas da página 
     * 
     * @param response 
     * @param isUpdate 
     * 
     */
    const changeStatics = (newsResponse: KlipboxNewsResponse | undefined, isUpdate?: boolean) => {
        if (newsResponse !== undefined) {
            const { data, page, total, totalPage, newNews } = newsResponse

            setStatics({
                count: !isUpdate && statics.count > 0 ? (data?.length || 0) : statics.count + (data?.length || 0),
                page,
                total,
                totalPage,
                newNews,
                isFetching: false,
                isFetched: true
            })
        }
    }

    return (
        <div className="dashboard">
            <MenuComponent name="Repercussão" groupsList={props.groupsList} />

            <div className="dashboard-content">
                {/* Dashboard Header */}
                <KlipboxDashboardHeader />

                {/* Dashboard */}
                <div className="dashboard-itens">
                    <p className="klipbox-results">
                        Mostrando {statics.isFetching ? "..." : statics.count.toLocaleString("pt-BR")} de {!statics.isFetched ? "..." : statics.total.toLocaleString("pt-BR")} notícias coletadas
                        <span className="klipbox-results-newNews">
                            { !statics.newNews ? <></> : 
                                <>
                                    (<strong>{statics.newNews?.toLocaleString("pt-BR")} novas notícias</strong> nas últimas 24 horas)
                                </> 
                            }
                        </span>
                    </p>

                    {/* Klipbox Filtro */}
                    <KlipboxFiltroComponent
                        onClick={requestFilter}
                        onChange={changeFiltro}
                        onReset={() => updateValues({ isReset: true })}
                        dateChange={changeDataRange}
                        dateShow={date}
                    />

                    {/* Klipbox Noticias */}
                    <KlipboxNoticiasComponent
                        noticias={noticias}
                        setNoticias={setNoticias}
                        statics={statics}
                        updateStatics={changeStatics}
                        isLoading={isLoading}
                        setLoading={setLoading}
                        noticiaId={noticiaId}
                        setNoticiaId={setNoticiaId}
                        scrollParameters={scrollParameters}
                        updateValues={updateValues}
                    />
                </div>
            </div>
        </div>
    )
}

export default KlipboxRepercussao