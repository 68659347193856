import { MenuPrivateProps } from "../../../@types/component.types";
import ClienteFiltroComponent from "../../../component/cliente-filtro";
import MenuComponent from "../../../component/menu";
import ConfiguracaoDashboardHeader from "../header";

const Clientes = (props: MenuPrivateProps) => {

    /**
     * @name changeFiltro - Modifica valores do filtro 
     * 
     * @param event 
     */
     const changeFiltro = (event: any) => {
        event.preventDefault()

        const { target } = event
        const { name, value } = target
    }

    return (
        <div className="dashboard">
            <MenuComponent name="Configurações" groupsList={props.groupsList}/>

            <div className="dashboard-content">
                {/* Dashboard Header */}
                <ConfiguracaoDashboardHeader />

                {/* Dashboard */}
                <div className="dashboard-itens">
                    {/* Clientes Filtro */}
                    <ClienteFiltroComponent
                        onChange={changeFiltro}
                        groupsList={props.groupsList}
                    />
                </div>
            </div>
        </div>
    )
}

export default Clientes;