import { useEffect, useState } from 'react'

// Types //
import { MenuPrivateProps } from "../../../@types/component.types";
import { Users, UsersCreate, UsersInterface } from '../../../@types/users.types';

// Component //
import MenuComponent from "../../../component/menu";
import ComponentTable from '../../../component/table';
import UsuarioFiltroComponent from "../../../component/usuario-filtro";
import ConfiguracaoDashboardHeader from "../header";

// Context //
import { useKlipbox } from '../../../context/klipbox.context';

// Service //
import { AlertsService } from '../../../services/alerts.service';

// CSS //
import "./usuarios.css"
import Swal from 'sweetalert2';
import { RequestsService } from '../../../services/requests.service';
import { environment } from '../../../common/environments';
import { Groups } from '../../../@types/groups.types';
import { formatPhoneNumber } from '../../../common/utilitaries';

const Usuarios = (props: MenuPrivateProps) => {

    const { fetchUsers, fetchGroups } = useKlipbox()

    const [isShowingInfo, setShowingInfo] = useState(false)
    const [filtro, setFiltro] = useState({} as { userStatus: string, limit: number })
    const [users, setUsers] = useState({} as UsersInterface)
    const [createUser] = useState({} as UsersCreate)

    const timeoutUsers = (ms: number, params?: {}, isReset?: boolean) => {
        const timeout = setTimeout(() => getUsers(isReset, params), ms)

        return () => {
            clearTimeout(timeout)
        }
    }

    /**
     * @name changeFiltro - Modifica valores do filtro 
     * 
     * @param event 
     */
    const changeFiltro = (event: any) => {
        event.preventDefault()

        const { target } = event
        const { value } = target
        if (filtro.userStatus === value) {
            return AlertsService.notification({ icon: "warning", title: "Você já está buscando por esse filtro" })
        }

        setFiltro({ userStatus: value, limit: 20 })
    }

    /**
     * @name resetFiltro - Reseta o filtro
     * 
     * @param event 
     */
    const resetFiltro = (event: any) => {
        event.preventDefault()
        if (filtro.userStatus === '') {
            return AlertsService.notification({ icon: "warning", title: "O filtro de usuários já foi resetado" })
        }

        setUsers({ isFetched: false, data: [] })
        setFiltro({ userStatus: '', limit: 20 })

        timeoutUsers(1000, { limit: 20 }, true)
    }

    /**
     * @name searchFiltro - Busca usuários usando filtro
     * 
     * @param event 
     */
    const searchFiltro = (event: any) => {
        event.preventDefault()
        if (Object.keys(filtro).length < 1 || filtro.userStatus === '') {
            return AlertsService.notification({ icon: "warning", title: "Não há um filtro selecionado para ser buscado" })
        }

        setUsers({ isFetched: false, data: [] })
        timeoutUsers(1000, filtro, true)
    }

    /**
     * @name addUser - Adiciona um usuário
     * 
     * @param event 
     */
    const addUser = async (event: any) => {
        event.preventDefault()

        const { value: name } = await Swal.fire({
            title: "Insira o nome do usuário",
            input: "text",
            inputLabel: "Nome de usuário",
            inputPlaceholder: "Alberto Nascimento",
            inputValidator: (value) => !value ? "Informe corretamente o nome do colaborador" : ""
        })

        if (!name) return
        createUser.name = name

        const { value: email } = await Swal.fire({
            title: "Insira o e-mail do usuário",
            input: "email",
            inputLabel: "E-mail do usuário",
            inputPlaceholder: "alberto.nascimento@gmail.com",
            validationMessage: "Informe corretamente o e-mail do colaborador"
        })

        if (!email) return
        createUser.email = email

        const { value: phone } = await Swal.fire({
            title: "Insira o telefone do usuário",
            input: "number",
            inputLabel: "Telefone do usuário (XX) X XXXX-XXXX (apenas números)",
            inputPlaceholder: "(92) 9 9999-9999",
            validationMessage: "Informe corretamente o e-mail do colaborador"
        })

        if (!phone) return
        createUser.phone = phone

        var groups = [] as Groups[]
        const inputGroups = new Promise((resolve) => {
            fetchGroups().then((response) => {
                const { data } = response

                const _data = [] as any
                data.forEach((group) => {
                    _data[group.id] = group.name
                })

                groups = data
                resolve(_data)
            })
        })

        var groupsDisplay = [] as any[]
        var groupsList = [] as Number[]

        await Swal.fire({
            title: "Selecione os grupos do usuário",
            input: "select",
            inputOptions: inputGroups,
            inputLabel: `Grupos selecionados: Nenhum.`,
            inputPlaceholder: "Selecione um grupo para o colaborador",
            inputValidator: () => {
                return new Promise((resolve) => 
                    resolve(groupsDisplay.length < 1 ? "Você precisa selecionar pelo menos um grupo para cadastrar o usuário" : null))
            },
            didOpen: () => {
                Swal.getInput()?.addEventListener('change', async (event: any) => {
                    const { target } = event
                    const { value } = target

                    const group = groups.filter((g) => g.id === Number(value))[0]
                    const findIndex = groupsDisplay.findIndex((obj) => obj === group.name)

                    if (findIndex !== -1) {
                        delete groupsDisplay[findIndex]
                        delete groupsList[findIndex]
                        groupsDisplay = groupsDisplay.filter((obj) => obj)
                    } else {
                        groupsDisplay.push(group.name)
                        groupsList.push(group.id)
                    }

                    document.getElementsByClassName("swal2-input-label")[0].innerHTML = 
                        `Grupos selecionados: ${groupsDisplay.length < 1 ? "Nenhum" : groupsDisplay.join(", ")}.`
                })
            }
        })

        createUser.groups = groupsList

        AlertsService.confirmation({
            title: 'Criação de usuário',
            icon: 'info',
            html: `
            Nome: ${createUser.name}<br />
            E-mail: ${createUser.email}<br />
            Telefone: ${formatPhoneNumber(createUser.phone)}<br />
            Grupos: ${groupsDisplay.join(", ")}.<br /><br />

            A senha de criação do usuário é a padrão.
            `,
            confirmButtonText: "Sim, cadastrar usuário"
        })
            // Sucesso //
            .then((result) => {
                // Não confirmou a desativação do usuário //
                if (!result.isConfirmed) {
                    return Swal.fire({ title: "Cancelado", text: "Você optou por não criar a conta do usuário", timer: 2000 })
                }
                
                // Aceitou a desativação da conta //
                Swal.fire({
                    title: `Criando a conta do usuário ${createUser.name}...`,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading()

                        // Atribuindo a senha padrão //
                        createUser.password = String(environment.SERVER.DEFAULT_PASSWORD)
                        
                        RequestsService.call({ method: "POST", url: environment.URLS.USER.CREATE, data: createUser })
                            // Sucesso //
                            .then((response) => {
                                const { data } = response
                                if (!data) {
                                    return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao criar o usuário. Contate o administrador para verificar o problema" })
                                }

                                const { success, message } = data
                                AlertsService.notification({ icon: !success ? "error" : "success", title: message })

                                // Ocorreu um erro //
                                if (!success) {
                                    return;
                                }

                                resetFiltro(event)
                            })
                            // Error //
                            .catch(() => AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao ativar o usuário" }))
                    }
                })
            })
    }
    
    /**
     * @name showInformation - Mostra as informações do usuário
     * 
     * @param user 
     */
    const showInformation = (user: Users) => {
        const _document = document.getElementById(`user-config-${user.id}`)
        if (_document) {
            _document.style.display = !isShowingInfo ? 'flex' : 'none';
        }

        const _showDown = document.getElementById(`user-show-down-${user.id}`)
        if (_showDown) {
            _showDown.style.display = !isShowingInfo ? 'none' : ''
        }

        const _showUp = document.getElementById(`user-show-up-${user.id}`)
        if (_showUp) {
            _showUp.style.display = !isShowingInfo ? '' : 'none'
        }

        setShowingInfo(!isShowingInfo)
    }

    /**
     * @name deleteUser - Desativa um usuário
     * 
     * @param user 
     * 
     * @returns Response
     */
    const deleteUser = (user: Users) => {
        if (!user) {
            return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao deletar o usuário. Por favor, reinicie a página" });
        }

        AlertsService.confirmation({
            title: 'Você tem certeza?',
            text: `Você estará desativando a conta do(a) ${user.name}`,
            confirmButtonText: "Sim, desativar",
            cancelButtonText: "Não"
        })
            // Sucesso //
            .then((result) => {
                // Não confirmou a desativação do usuário //
                if (!result.isConfirmed) {
                    return Swal.fire({ title: "Cancelado", text: "Você optou por não desativar a conta do usuário", timer: 2000 })
                }
                
                // Aceitou a desativação da conta //
                Swal.fire({
                    title: `Desativando o usuário do(a) ${user.name}...`,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading()
                        
                        RequestsService.call({ method: "DELETE", url: environment.URLS.USER.DEL + user.id })
                            // Sucesso //
                            .then((response) => {
                                const { data } = response
                                if (!data) {
                                    return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao desativar o usuário. Contate o administrador para verificar o problema" })
                                }

                                const { success, message } = data
                                AlertsService.notification({ icon: !success ? "error" : "success", title: message })

                                // Ocorreu um erro //
                                if (!success) {
                                    return;
                                }

                                const _newList = users.data.filter((u) => u.id !== user.id)
                                setUsers({ isFetched: true, data: _newList })
                            })
                            // Error //
                            .catch(() => AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao desativar o usuário" }))
                    }
                })
            })
    }

    /**
     * @name activeUser - Ativa um usuário
     * 
     * @param user 
     * 
     * @returns Response
     */
    const activeUser = (user: Users) => {
        if (!user) {
            return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao deletar o usuário. Por favor, reinicie a página" });
        }

        AlertsService.confirmation({
            title: 'Você tem certeza?',
            text: `Você estará ativando a conta do(a) ${user.name}`,
            confirmButtonText: "Sim, ativar",
            cancelButtonText: "Não"
        })
            // Sucesso //
            .then((result) => {
                // Não confirmou a desativação do usuário //
                if (!result.isConfirmed) {
                    return Swal.fire({ title: "Cancelado", text: "Você optou por não ativar a conta do usuário", timer: 2000 })
                }
                
                // Aceitou a desativação da conta //
                Swal.fire({
                    title: `Ativando o usuário do(a) ${user.name}...`,
                    allowOutsideClick: false,
                    didOpen: async () => {
                        Swal.showLoading()
                        
                        RequestsService.call({ method: "PUT", url: environment.URLS.USER.RESTORE + user.id })
                            // Sucesso //
                            .then((response) => {
                                const { data } = response
                                if (!data) {
                                    return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao ativar o usuário. Contate o administrador para verificar o problema" })
                                }

                                const { success, message } = data
                                AlertsService.notification({ icon: !success ? "error" : "success", title: message })

                                // Ocorreu um erro //
                                if (!success) {
                                    return;
                                }

                                const _newList = users.data.filter((u) => u.id !== user.id)
                                setUsers({ isFetched: true, data: _newList })
                            })
                            // Error //
                            .catch(() => AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao ativar o usuário" }))
                    }
                })
            })
    }

    /**
     * @name updateInfo - Atualiza um usuário
     * 
     * @param user 
     * @returns 
     */
    const updateInfo = async (user: Users) => AlertsService.notification({ icon: 'error', title: `This function is not implemented.` })

    /**
     * @name getUsers - Obtém a lista de usuários cadastrados
     */
    const getUsers = async (isReset?: boolean, params?: {}) => {
        // Verificando se já foi buscado os usuários //
        if (users.isFetched && !isReset) {
            return;
        }

        const users_response = await fetchUsers(params)
        if (!users_response) {
            return;
        }

        const { success, message, data } = users_response
        if (!success) {
            return AlertsService.notification({ title: message, icon: 'error' })
        }

        setUsers({ isFetched: true, data })
        AlertsService.notification({ icon: "success", title: "Usuários foram obtidos com sucesso!" })
    }

    useEffect(() => {
        const timeout = setTimeout(() => getUsers(false, { limit: 20 }), 1500)
        return () => clearTimeout(timeout)
    }, [])

    return (
        <div className="dashboard">
            <MenuComponent name="Configurações" groupsList={props.groupsList} />

            <div className="dashboard-content">
                {/* Dashboard Header */}
                <ConfiguracaoDashboardHeader />

                {/* Dashboard */}
                <div className="dashboard-itens">
                    {/* Usuarios Filtro */}
                    <UsuarioFiltroComponent
                        onChange={changeFiltro}
                        onReset={resetFiltro}
                        onClick={searchFiltro}
                        addUser={addUser}
                        groupsList={props.groupsList}
                    />

                    {/* Dados da Tabela */}
                    <ComponentTable
                        data={users.isFetched ? users.data : []}
                        titles={["Nome", "Telefone", "Grupos", "Status", "Ações"]}
                        firstStyle={{ width: "35%" }}
                        type="users"
                        dataIsLoaded={users.isFetched || false}
                        actions={{
                            active: activeUser,
                            delete: deleteUser,
                            info: showInformation,
                            updateInfo,
                            isShowingInfo
                        }}
                    />
                </div>
            </div>
        </div>
    )

}

export default Usuarios