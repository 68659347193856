import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Top10Interface } from "../../@types/top10.types"
import { formatDate } from "../../common/utilitaries"

// Image //
import logo from '../../files/images/marca-nova.png'

// CSS //
import './top10.css'
import { RequestsService } from "../../services/requests.service"
import { environment } from "../../common/environments"
import { Top10Response } from "../../@types/top10.types"
import { BiError } from "react-icons/bi"

const Top10 = () => {

    const params = useParams()
    const [news, setNews] = useState({ isFetched: false, isFetching: true } as Top10Interface)

    const searchNewsById = () => {
        RequestsService.call({ 
            requestName: "fetchNewsById", 
            method: "GET", 
            baseUrl: environment.URLS.BASE.NEWS,  
            url: environment.URLS.KLIPBOX.TOP10 + params.id 
        })
            // Sucesso //
            .then((request) => {
                const response: Top10Response = request.data
                
                // Ocorreu um erro na busca dos dados //
                if (!response.success) {
                    setNews({ isFetched: true, isFetching: false, hasError: true, errorMessage: response.message })
                    return;
                }

                setNews({ isFetched: true, isFetching: false, hasError: false, response })
            })
            // Error //
            .catch((err) => {
                console.log(err)
                setNews({
                    isFetched: true,
                    isFetching: false,
                    hasError: true,
                    errorMessage: err
                })
            })
    }

    const showError = () => {
        return (
            <div className="top10-error">
                <BiError />
                <h1>Ocorreu um erro ao buscar as notícias.</h1>
                <p>{news.errorMessage}</p>
                <p>{window.location.hostname}</p>
            </div>
        )
    }

    useEffect(() => {
        const search = setTimeout(() => {
            if (!news.isFetched) {
                searchNewsById()
            }
        }, 0)

        return () => clearTimeout(search)
    }, [])

    return (
        <div className="top10">
            {/* LOGO */}
            <div className="top10-logo-header">
                <img alt="logo" src={logo} />
            </div>

            <div className="top10-info">
                <p>Mostrando {news.isFetching || news.hasError ? "..." : news.response?.count?.toLocaleString("pt-BR")} notícias relacionadas</p>
            </div>

            {/* Items */}
            <div className="top10-items">
                {/* ITEM */}
                {
                    news.hasError ? showError() : news.response?.data.map((noticia, index) => (
                        <div className={`top10-item ${noticia.classification === "Positiva" ? "component-klipbox-noticia-item-positiva" : "component-klipbox-noticia-item-negativa"}`} key={index}>
                            <div className="top10-item-header">
                                <p>{noticia.monitoring.name}</p>
                                <p>{formatDate(noticia)}</p>
                            </div>

                            <div className="top10-item-title">
                                <p>{noticia.title}</p>
                            </div>

                            <div className="top10-item-content">
                                <p>Source: {noticia.extend.source}</p>
                                <p>ID: #{noticia.id}</p>
                            </div>

                            <div className="top10-item-footer">
                                <p>{noticia.classification}</p>
                                <a className="component-klibpox-noticia-cursor" title="Compartilhar" href={noticia.link} target="_blank" rel="noreferrer">
                                    Ir à matéria
                                </a>
                            </div>
                        </div>
                    ))
                }
            </div>

            {/*  */}
        </div>
    )
}

export default Top10