import React from "react";
import { BiShare } from "react-icons/bi";

// CSS //
import "./klipbox-noticias-skeleton.css"

const KlipboxNoticiasSkeletonComponent = () => {
    return (
        <div className="klipbox-noticias">
            {Array(10).fill(0).map((item, index) => (
                <div key={index} className="component-klipbox-noticia-skeleton-item">
                    {/* Header */}
                    <div className="component-klipbox-noticia-skeleton-item-header">
                        <span className="component-klipbox-noticia-skeleton-loading">Klipbox Skeleton</span>
                        <span className="component-klipbox-noticia-skeleton-loading">22/07/2022</span>
                    </div>

                    {/* Content */}
                    <div className="component-klipbox-noticia-skeleton-item-content">
                        <p className="component-klipbox-noticia-skeleton-loading">Klipbox Noticias Skeleton. Klipbox Noticias Skeleton.</p>
                        <p className="component-klipbox-noticia-skeleton-loading">Klipbox Noticias Skeleton. Klipbox Noticias Skeleton.</p>
                    </div>

                    {/* Footer */}
                    <div className="component-klipbox-noticia-skeleton-item-footer">
                        <span className="component-klipbox-noticia-skeleton-loading">Não analisado</span>
                        <span className="component-klipbox-noticia-skeleton-loading" title="Compartilhar">
                            <BiShare />
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default KlipboxNoticiasSkeletonComponent;