import { Bar } from "@ant-design/plots"
import moment from "moment"
import { useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { BarData } from "../../../../@types/graphs.data.types"
import { KlipboxGraphTotalResponse } from "../../../../@types/klipbox.response.types"
import { environment } from "../../../../common/environments"
import { useKlipbox } from "../../../../context/klipbox.context"
import { AlertsService } from "../../../../services/alerts.service"

// CSS //
import "./private.css"

const ComponentGraphBarTotal = () => {

    const { fetchGraph } = useKlipbox()

    const [barDataGeral, setBarDataGeral] = useState({} as BarData[])
    const [isLoading, setLoading] = useState(true)

    const queryParams = new URLSearchParams(window.location.search)
    const accessToken = queryParams.get("accessToken")
    const token = queryParams.get("token")
    const startDate = queryParams.get("startDate")
    const endDate = queryParams.get("endDate")
    const clientId = queryParams.get("clientId")

    useEffect(() => {
        const params = {
            clientId: clientId || 2,
            startDate: startDate || moment().startOf('month').format("YYYY-MM-DD"),
            endDate: endDate || moment().endOf('month').format("YYYY-MM-DD"),
            token: accessToken
        }

        const getData = setTimeout(async () => {
            // Verificando se existe acesso //
            if (!token || token !== environment.URLS.GRAPH_TOKEN) {
                return
            }

            // PieData //
            const response: KlipboxGraphTotalResponse = await fetchGraph(environment.URLS.KLIPBOX.GRAPH.MONITORING_TOTAL, params)
            if (response) {
                const { success, data } = response
                if (success && data) {
                    const result = [] as BarData[]

                    // Fazendo o parse dos dados //
                    data.forEach((item) => {
                        let monitoringName = undefined
                        if (item.monitoramento.indexOf("GOV") !== -1) {
                            monitoringName = item.monitoramento.split(" - ")[1]
                            if (monitoringName.indexOf("G1") !== -1) {
                                monitoringName = "Governo do Amazonas"
                            }
                        }

                        result.push({
                            primary: monitoringName || item.monitoramento,
                            type: "Total",
                            value: item.total
                        })
                    })

                    // Ordenando do maior para o menor //
                    data.sort((a, b) => b.total > a.total ? 1 : 0)

                    // Setando os resultados //
                    setBarDataGeral(result)
                    setLoading(false)
                }
            }
        })

        return () => clearTimeout(getData)
    }, [])

    if (!token || token !== environment.URLS.GRAPH_TOKEN) {
        AlertsService.notification({ icon: "error", title: "Você não tem permissões suficientes para acessar essa rota." })
        return <Navigate to={environment.ROUTES.DASHBOARD} replace />
    }

    if (isLoading) {
        return <></>
    }

    return (
        <div className="private-graph">
            <Bar
                data={barDataGeral}
                isStack={true}
                xField="value"
                yField="primary"
                seriesField="type"
                className="component-bar-canvas"
                legend={false}
                label={{
                    position: "bottom",
                    style: { fontFamilly: "Poppins", fontSize: 30, fill: "black" },
                    formatter: (v) => v.value.toLocaleString("pt-BR"),
                }}
                yAxis={{
                    label: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 30 },
                        formatter: (v) => v.toUpperCase()
                    }
                }}
                xAxis={{
                    label: {
                        style: { fill: "black", fontFamily: "Poppins", fontSize: 30, textAlign: "end" }
                    }
                }}
                minBarWidth={40}
                maxBarWidth={40}
            />
        </div>
    )
}

export default ComponentGraphBarTotal