// Types //
import { UserFiltroProps } from "../../@types/component.types";

// Component //
import ButtonComponent from "../button";
import FiltroItemComponent from "../filtro-item";

// CSS //
import "./cliente-filtro.css"

const ClienteFiltroComponent = (props: UserFiltroProps) => {

    const user_status = [
        { id: undefined, name: "Status do usuário" },
        { id: "Ativo", name: "Ativo" },
        { id: "Inativo", name: "Inativo" },
        { id: "Todos", name: "Todos" },
    ]

    return (
        <div className="config-client-filtro">
            {/* Status do usuário */}
            <FiltroItemComponent
                type="select"
                id="userStatus"
                name="userStatus"
                title="Status do usuário"
                onChange={props.onChange}
                childrens={
                    user_status.map((_class, index) => (
                        <option key={index} value={_class.id}>{_class.name}</option>
                    ))
                }
            />

            {/* Button */}
            <ButtonComponent
                text="Buscar"
                type="submit"
                onClick={props.onClick}
                class="config-client-filtro-button"
            />

            {/* Button reset */}
            <ButtonComponent
                text="Resetar"
                type="reset"
                onClick={props.onReset}
                class="config-client-filtro-button"
            />
        </div>
    )
}

export default ClienteFiltroComponent;