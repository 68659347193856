import { NotificationItemProps } from "../../@types/component.types"

// CSS //
import "./notification-item.css";

const NotificationItemComponent = (props: NotificationItemProps) => {

    const parseType = (type: "Notificação" | "Atualização" | "Correção" | "Implementação" | undefined) => {
        let _class = "component-notification-category"

        switch (type) {
            case "Correção": {
                _class += " category-fix"
                break;
            }
            case "Implementação": {
                _class += " category-implementation"
                break;
            }
            case "Notificação": {
                _class += " category-notification"
                break;
            }
        }

        return _class
    }

    return (
        <div className="component-notifications-itens">
            {props.notifications?.map((notification, index) => (
                <div className="component-notification-content-item" key={index}>
                    <span className={parseType(notification.type)}>{notification.type}</span>
                    <span className="component-notification-title">{notification.title}</span>
                    <span className="component-notification-description">{notification.description}</span>
                </div>
            ))}
        </div>
    )
}

export default NotificationItemComponent