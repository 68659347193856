import { useEffect, useState } from "react";
import { formatClientNameFromKlipboxNews, formatDate, formatMonitoringNameFromKlipboxNews, formatNewsDescription, getClassifications } from "../../common/utilitaries"
import parse from "html-react-parser"

// Context //
import { useKlipbox } from "../../context/klipbox.context";

// Types //
import { KlipboxNoticiaProps } from "../../@types/component.types";
import { MonitoringsInterface } from "../../@types/monitorings.types";
import { KlipboxNoticia, KlipboxUpdate } from "../../@types/klipbox.types";

// Component //
import ButtonComponent from "../button";
import FiltroItemComponent from "../filtro-item";
import KlipboxNoticiaSkeleton from "./klipbox-noticia-skeleton";

// CSS //
import "./klipbox-noticia.css"
import { AlertsService } from "../../services/alerts.service";
import { ClientInterface } from "../../@types/client.types";

const KlipboxNoticiaComponent = (props: KlipboxNoticiaProps) => {

    const { fetchNew, updateNew, deleteNew, fetchMonitorings, fetchClients } = useKlipbox()

    const [noticiaUpdate] = useState({} as KlipboxUpdate)
    const [noticia, setNoticia] = useState({} as KlipboxNoticia)
    const [clients, setClients] = useState({ isFetched: false, data: [] } as ClientInterface)
    const [monitorings, setMonitorings] = useState({ isFetched: false, data: [] } as MonitoringsInterface)

    /**
     * @name deleteKlipboxNew - Deleta uma matéria do Klipbox
     * 
     * @param e 
     */
    const deleteKlipboxNew = (e: any) => {
        e.preventDefault()

        AlertsService.confirmation({
            title: "Desativar notícia",
            text: "Você tem certeza que deseja desativar essa notícia?",
            confirmButtonText: "Sim, desativar notícia.",
            cancelButtonText: "Cancelar"
        })
            // Sucesso //
            .then((result) => {
                if (!result.isConfirmed)
                    return

                return deleteNew(noticia.id)
            })
            // Sucesso em deletar a notícia //
            .then((updateResult) => {
                if (!updateResult) {
                    return AlertsService.notification({ icon: "info", title: "Você escolheu não desativar a notícia." })
                }

                const { success, message } = updateResult
                // Notícia não deletada //
                if (!success) {
                    return AlertsService.notification({ icon: "error", title: message })
                }

                AlertsService.notification({ icon: "success", title: message })
                props.updateValues({ deleteNew: true, newId: noticia.id })
            })
            // Error //
            .catch(() => AlertsService.notification({ icon: "error", title: "Ocorreu um erro em deletar a notícia." }))
    }

    const updateKlipboxNew = (e: any) => {
        e.preventDefault()

        // Verificando se há alterações //
        if (Object.keys(noticiaUpdate).length < 1) {
            return AlertsService.notification({ icon: 'error', title: "Não há alterações a serem salvas da notícia" })
        }

        AlertsService.confirmation({
            title: "Atualizar notícia",
            text: "Você tem certeza que deseja atualizar a notícia?"
        })
            // Sucesso em atualizar a notícia //
            .then((result) => {
                if (!result.isConfirmed)
                    return

                return updateNew(noticia.id, noticiaUpdate)
            })
            // Sucesso em atualizar a notícia //
            .then((updateResult) => {
                // Notícia não atualizada //
                if (!updateResult?.success) {
                    return AlertsService.notification({ icon: "error", title: updateResult?.message })
                }

                const { data } = updateResult;
                const index = props.noticias.findIndex((noticia, i) => noticia.id === data.id)
                const _noticias = props.noticias
                _noticias[index].classification = noticiaUpdate.classification
                _noticias[index].monitoring = data.monitoring

                // Update new //
                AlertsService.notification({ icon: "success", title: updateResult.message })
                props.updateValues({ newsUpdate: true, newNews: _noticias })
            })
            // Error //
            .catch(() => AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao atualizar a notícia no banco de dados" }))

        // updateNew(noticia.id, noticiaUpdate)
    }

    const updateNewValues = (event: any) => {
        event.preventDefault()

        const { target } = event
        const { name, value } = target

        switch (name) {
            case "classification": {
                return noticiaUpdate.classification = value
            }

            case "monitoringId": {
                return noticiaUpdate.monitoringId = Number(value)
            }

            case "clientId": {
                return noticiaUpdate.clientId = Number(value)
            }
        }
    }

    const searchNew = async () => {
        // Promises //
        const [_noticia, _monitorings, _clients] = await Promise.all([
            fetchNew(props.noticiaId),
            fetchMonitorings({ "all": true }),
            fetchClients({ "all": true })
        ])

        // Ocorreu um erro //
        if (!_noticia) {
            return AlertsService.notification({ icon: 'error', title: "Ocorreu um erro ao buscar a notícia do Klipbox" })
        }

        if (!_noticia.client) {
            _noticia.client = {
                id: -1, name: "Não encontrado", avatar: "", capa: "", sigla: "NAE", created_at: new Date(), updated_at: new Date()
            }
        }

        if (!_noticia.monitoring) {
            _noticia.monitoring = {
                id: -1, name: "Não encontrado", monitoring_id: "-1", monitoring_classification: "", terms: "", required_terms: "", created_at: new Date(), updated_at: new Date()
            }
        }

        setNoticia(_noticia)
        setMonitorings({ data: _monitorings.data, isFetched: true })

        const _dataClient = [
            { id: -1, name: "Não encontrado", sigla: "NAE", avatar: "", capa: "", created_at: new Date(), updated_at: new Date() },
        ]

        _clients.data.forEach((client) => _dataClient.push(client))
        setClients({ data: _dataClient, isFetched: true })

        const timeout = setTimeout(() => props.setLoading(false), 600)
        return () => clearTimeout(timeout)
    }

    useEffect(() => {
        const updateValues = setTimeout(async () => await searchNew(), 0)
        return () => clearTimeout(updateValues)
    }, [props.noticiaId])

    if (props.isLoading || noticia === undefined) {
        return <KlipboxNoticiaSkeleton />
    }

    return (
        <div className="component-klipbox-noticia">
            {/* DATA E CLIENTE */}
            <div className="component-klipbox-noticia-header">
                <p>
                    ({(noticia.id || -1).toLocaleString("pt-BR")}) {formatClientNameFromKlipboxNews(noticia)} | {formatMonitoringNameFromKlipboxNews(noticia)} - {noticia.monitoring_name || "Não encontrado"}</p>
                <p>{formatDate(noticia)}</p>
            </div>

            {/* TITLE */}
            <div className="component-klipbox-noticia-title">
                <h1>{noticia.title}</h1>
            </div>

            {/* LINK */}
            <div className="component-klipbox-noticia-filtros component-klipbox-noticia-link">
                <p>Link da matéria</p>

                <FiltroItemComponent
                    type="input"
                    id="link"
                    name="link"
                    placeholder={noticia.link}
                    value={noticia.link}
                    readOnly={true}
                />
            </div>

            {/* FILTROS LIST*/}
            <div className="component-klipbox-noticia-filtros-list">
                {/* CLASSIFICATION */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Classificação</p>

                    <FiltroItemComponent
                        type="select"
                        id="classification"
                        name="classification"
                        value={noticia.classification || "Não analisado"}
                        childrens={
                            getClassifications(true).filter((value) => value.id).map((value, index) => (
                                <option key={index} value={value.id}>{value.name}</option>
                            ))
                        }
                        onChange={updateNewValues}
                    />
                </div>

                {/* IA CLASSIFICATION */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Saven IA</p>

                    <FiltroItemComponent
                        type="input"
                        id="ia_classification"
                        name="ia_classification"
                        placeholder={noticia.ia_classification || "Não analisado"}
                        readOnly={true}
                    />
                </div>

                {/* CLIENT */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Cliente</p>

                    <FiltroItemComponent
                        type="select"
                        id="clientId"
                        name="clientId"
                        value={(noticia.client.id).toString()}
                        childrens={
                            clients.data.map((value, index) => (
                                <option key={index} value={value.id}>{value.name}</option>
                            ))
                        }
                        onChange={updateNewValues}
                    />
                </div>

                {/* MONITORING */}
                <div className="component-klipbox-noticia-filtros">
                    <p>Monitoramento</p>

                    <FiltroItemComponent
                        type="select"
                        id="monitoringId"
                        name="monitoringId"
                        value={(noticia.monitoring.id).toString()}
                        childrens={
                            monitorings.data.map((value, index) => (
                                <option key={index} value={value.id}>{value.name}</option>
                            ))
                        }
                        onChange={updateNewValues}
                    />
                </div>
            </div>

            {/* MATERIA CONTENT */}
            <div className="component-klipbox-noticia-content">
                <h1>Matéria</h1>

                <div className="component-klipbox-noticia-contents">
                    {parse(formatNewsDescription(noticia))}
                </div>
            </div>

            {/* BUTTON ACTIONS */}
            <div className="component-klipbox-noticia-button-actions">
                {/* Atualizar Matéria */}
                <ButtonComponent
                    text="Atualizar matéria"
                    type="button"
                    class="component-klipbox-noticia-button-action component-color-gold"
                    style={{ color: "black" }}
                    onClick={updateKlipboxNew}
                />

                {/* Excluir matéria */}
                <ButtonComponent
                    text="Excluir matéria"
                    type="button"
                    class="component-klipbox-noticia-button-action component-color-red"
                    onClick={deleteKlipboxNew}
                />
            </div>
        </div>
    )

}

export default KlipboxNoticiaComponent;