import React, { useMemo } from 'react';
import { BiShare } from "react-icons/bi";
import { KlipboxNoticiasItemProps } from "../../@types/component.types";
import { KlipboxNoticia } from "../../@types/klipbox.types";
import { formatDate } from "../../common/utilitaries";

// CSS //
import "./klipbox-noticias-item.css";

const KlipboxNoticiasItem = (props: KlipboxNoticiasItemProps) => {

    /**
     * @name parseClassification - Obtém a classificação da notícia corretamente //
     * 
     * @param noticia 
     * 
     * @returns String
     */
    const parseClassification = (noticia: KlipboxNoticia) => {
        if (noticia.ia_classification) {
            return noticia.ia_classification
        }

        if (noticia.classification) {
            return noticia.classification
        }

        return "Não analisado"
    }

    /**
     * @name parseCardColor - Obtém a classe para especificar a cor do card 
     * 
     * @param noticia 
     * 
     * @returns String
     */
    const parseCardColor = (noticia: KlipboxNoticia) => {
        switch (noticia.classification) {

            case "Positiva": {
                return " component-klipbox-noticia-item-positiva"
            }

            case "Negativa": {
                return " component-klipbox-noticia-item-negativa"
            }

            case "Neutra": {
                return " component-klipbox-noticia-item-neutra"
            }

            default: {
                return " component-klipbox-noticia-item-nao-analisado"
            }
        }
    }

    return (
        <div className="klipbox-noticias" onScroll={props.onScroll}>
            {props.noticias?.map((noticia, index) => (
                <div key={index} className={"component-klipbox-noticia-item" + parseCardColor(noticia)} onClick={() => props.showNews(noticia)}>
                    {/* Header */}
                    <div className="component-klipbox-noticia-item-header component-klibpox-noticia-cursor">
                        <span>{noticia.monitoring_name}</span>
                        <span>{formatDate(noticia)}</span>
                    </div>

                    {/* Content */}
                    <div className="component-klipbox-noticia-item-content component-klibpox-noticia-cursor">
                        <p>{noticia.title}</p>
                    </div>

                    {/* Footer */}
                    <div className="component-klipbox-noticia-item-footer">
                        <span>{parseClassification(noticia)}</span>
                        <span className="component-klibpox-noticia-cursor" title="Compartilhar">
                            <BiShare />
                        </span>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default KlipboxNoticiasItem;