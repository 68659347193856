import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './context/auth.context';
import RoutesList from './routes';

class App extends Component {

    render(): React.ReactNode {
        return (
            <BrowserRouter>
                <AuthProvider>
                    <RoutesList />
                </AuthProvider>
            </BrowserRouter>
        )
    }
}

export default App;
