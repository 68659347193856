import React from "react";

// Types //
import { InteractionProps } from "../../@types/component.types";

// CSS //
import "./interaction.css"

const ComponentInteraction = (props: InteractionProps) => {
    return (
        <div className="component-interaction">
            <div className="component-interaction-content">
                {/* ICON */}
                <div className="component-interaction-icon">
                    {props.icon}
                </div>

                {/* TEXT */}
                <div className="component-interaction-text">
                    <h1>{props.text}</h1>
                </div>
            </div>
        </div>
    )
}

export default ComponentInteraction;