import React from "react"
import { PrivateMenuState } from "../@types/route.types"
import { environment } from "../common/environments"
import { existsValueInArray } from "../common/utilitaries"

const MenuPrivateProvider: React.FC<PrivateMenuState> = ({ group, groupsList, children }) => {

    const redirectToChildren = () => {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>
        )
    }

    // Verifica se existe o grupo no Array //
    if (existsValueInArray(group, groupsList)) {
        return redirectToChildren()
    }
    
    if (groupsList.length > 0) {
        // Verificando se existe o grupo Admin //
        if (groupsList.indexOf(environment.GROUPS.ADMIN) !== 0) {
            // Grupo Admin não existe para o usuário //

            if (!existsValueInArray(group, groupsList)) {
                return <></>
            }
        }
    }

    return redirectToChildren()
}

export { MenuPrivateProvider }