import { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { LoginRedirectRoute } from './redirect/login.redirect.route';
import { PrivateRoute } from './private.route';

// Common //
import { environment } from '../common/environments';

// Services //
import { RequestsService } from '../services/requests.service';
import { AlertsService } from '../services/alerts.service';

// Context //
import { useAuth } from '../context/auth.context';
import { KlipboxProvider } from '../context/klipbox.context';

// Pages //
import Login from '../pages/login';
import Dashboard from '../pages/dashboard';
import KlipboxRepercussao from '../pages/klipbox-repercussao';
import KlipboxRepercussaoRelatorio from '../pages/klipbox-repercussao/relatorio';
import ComponentGraphPie from '../pages/klipbox-repercussao/relatorio/graphs/pie.graph';
import ComponentGraphLine from '../pages/klipbox-repercussao/relatorio/graphs/line.graph';
import ComponentGraphBarTotal from '../pages/klipbox-repercussao/relatorio/graphs/barTotal.graph';
import ComponentGraphBarCategory from '../pages/klipbox-repercussao/relatorio/graphs/barCategory.graph';
import Usuarios from '../pages/configuracao/usuarios';
import Clientes from '../pages/configuracao/clientes';
import Top10 from '../pages/top10'
import { StorageService } from '../services/storage.service';

const RoutesList = () => {

    const navigator = useNavigate()
    const { isLogged, removeLoggedAfterTokenExpired, } = useAuth()
    const [groupsList, setGroups] = useState([] as string[]);

    // Verifica os grupos do usuário //
    useEffect(() => {
        const requestGroups = setTimeout(() => {
            // Verificando se o usuário está logado //            
            if (isLogged()) {
                RequestsService.call({ requestName: "userRoutes", method: "GET", url: environment.URLS.USER.INFO })
                    // Sucesso //
                    .then((response) => {
                        const { data } = response
                        const _groups = data.data.groups

                        const findGroups = _groups.map((g: any) => g.name)
                        setGroups(findGroups)
                    })
                    // Error //
                    .catch((err) => {
                        const { data } = err.response
                        console.log("Ocorreu um erro ao verificar o grupo do usuário.", err.message)
                        if (data) {
                            const { message } = data

                            // Removendo o token e a data //
                            removeLoggedAfterTokenExpired()

                            // Enviando-o para a área de login //
                            navigator("/", { replace: true })

                            AlertsService.notification({ icon: "info", title: message, timer: 5000 })
                        }
                    })
            }
        }, 0);

        return () => clearTimeout(requestGroups)
    }, [isLogged()])

    return (
        <Routes>
            <Route path="/" element={(
                <LoginRedirectRoute route={environment.ROUTES.DASHBOARD}>
                    <Login />
                </LoginRedirectRoute>
            )} />

            <Route path={environment.ROUTES.DASHBOARD} element={(
                <PrivateRoute>
                    <Dashboard groupsList={groupsList} />
                </PrivateRoute>
            )} />

            {/* Klipbox Repercussão */}
            <Route path={environment.ROUTES.KLIPBOX.REPERCUSSAO} element={(
                <PrivateRoute group={environment.GROUPS.MONITORAMENTO} groupsList={groupsList}>
                    <KlipboxProvider>
                        <KlipboxRepercussao groupsList={groupsList} />
                    </KlipboxProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.KLIPBOX.RELATORIO} element={(
                <PrivateRoute group={environment.GROUPS.MONITORAMENTO} groupsList={groupsList}>
                    <KlipboxProvider>
                        <KlipboxRepercussaoRelatorio groupsList={groupsList} />
                    </KlipboxProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURACOES.USUARIOS} element={(
                <PrivateRoute group={environment.GROUPS.ADMIN} groupsList={groupsList}>
                    <KlipboxProvider>
                        <Usuarios groupsList={groupsList} />
                    </KlipboxProvider>
                </PrivateRoute>
            )} />

            <Route path={environment.ROUTES.CONFIGURACOES.CLIENTES} element={(
                <PrivateRoute group={environment.GROUPS.ADMIN} groupsList={groupsList}>
                    <KlipboxProvider>
                        <Clientes groupsList={groupsList} />
                    </KlipboxProvider>
                </PrivateRoute>
            )} />

            {/* GRAPHS */}
            <Route path={environment.ROUTES.GRAPHS.PIE} element={(
                <KlipboxProvider>
                    <ComponentGraphPie />
                </KlipboxProvider>
            )} />
            <Route path={environment.ROUTES.GRAPHS.LINE} element={(
                <KlipboxProvider>
                    <ComponentGraphLine />
                </KlipboxProvider>
            )} />
            <Route path={environment.ROUTES.GRAPHS.BAR_TOTAL} element={(
                <KlipboxProvider>
                    <ComponentGraphBarTotal />
                </KlipboxProvider>
            )} />
            <Route path={environment.ROUTES.GRAPHS.BAR_CATEGORY} element={(
                <KlipboxProvider>
                    <ComponentGraphBarCategory />
                </KlipboxProvider>
            )} />

            {/* TOP 10 */}
            <Route path={environment.ROUTES.TOP10} element={(
                <KlipboxProvider>
                    <Top10 />
                </KlipboxProvider>
            )} />

            <Route path='*' element={<Navigate to={environment.ROUTES.DASHBOARD} />} />
        </Routes>
    );
}

export default RoutesList
