import "./notification-skeleton.css";

const NotificationSkeletonComponent = () => {
    return (
        <div className="component-notifications-itens">
            {
                Array(2).fill(2)
                    .map((item, index) => (
                        <div className="component-notification-skeleton-content-item" key={index}>
                            <span className="component-notification-skeleton-loading component-notification-skeleton-category">Skeleton Loading</span>
                            <span className="component-notification-skeleton-loading component-notification-skeleton-title">v1.0.0 Skeleton Loading</span>
                            <div className="component-notification-skeleton-loading component-notification-skeleton-description" />
                            <div className="component-notification-skeleton-loading component-notification-skeleton-description" />
                        </div>
                    ))
            }
        </div>
    )
}

export default NotificationSkeletonComponent;