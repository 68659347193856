import { ButtonProps } from "../../@types/component.types"
import "./button.css"

/**
 * @name ButtonComponent - Component dos botões
 * 
 * @param props 
 * 
 * @returns Component
 */
const ButtonComponent = (props: ButtonProps) => {
    const className = "component-button" + (props.class === undefined ? "" : " " + props.class)

    return (
        <button
            name={props.name}
            className={className}
            type={props.type}
            value={props.value}
            style={props.style}
            disabled={props.disabled}
            onClick={props.onClick}
        >
            {props.text}
        </button>
    )
}

export default ButtonComponent