import axios, { AxiosRequestConfig } from 'axios';
import { RequestsProps } from '../@types/request.types';
import { environment } from '../common/environments';
import { StorageService } from './storage.service';

/**
 * @name call - Realiza uma requisição 
 * 
 * @param props 
 * 
 * @returns Promise
 */
const call = (props: RequestsProps): Promise<any> => {
    // Atribuíndo o token //
    const token = StorageService.getToken()
    if (token) {
        if (props.headers) {
            props.headers["authorization"] = token
        } else {
            props.headers = {
                "authorization": token
            }
        }
    }

    if (props.params) {
        if (props.params["token"]) {
            props.headers = {
                "authorization": props.params["token"]
            }

            props.params["token"] = undefined
        }
    }

    const abortController = new AbortController()
    const parameters = {
        baseURL: props.baseUrl || environment.SERVER.BASEURL,
        method: props.method,
        url: props.url,
        data: props.data,
        headers: props.headers,
        params: props.params,
        responseType: props.responseType || "json",
        signal: abortController.signal
    } as AxiosRequestConfig

    return axios(parameters)
}

export const RequestsService = {
    call,
}