// Types //
import { BiPlusCircle } from "react-icons/bi";
import { UserFiltroProps } from "../../@types/component.types";
import { existsValueInArray, getClassifications } from "../../common/utilitaries";

// Component //
import ButtonComponent from "../button";
import FiltroItemComponent from "../filtro-item";

// CSS //
import "./usuario-filtro.css"
import { environment } from "../../common/environments";

const UsuarioFiltroComponent = (props: UserFiltroProps) => {

    const user_status = [
        { id: undefined, name: "Status do usuário" },
        { id: "Ativo", name: "Ativo" },
        { id: "Inativo", name: "Inativo" },
        { id: "Todos", name: "Todos" },
    ]

    const showButtonAddUser = () => {
        if (props.groupsList.length < 1) return <></>

        if (props.groupsList.indexOf(environment.GROUPS.ADMIN)  !== 0) {
            if (!existsValueInArray(environment.GROUPS.ADMIN, props.groupsList)) {
                return <></>
            }
        }

        return (
            <button type="button" title="Adicionar usuário" onClick={props.addUser} className="config-user-filtro-button-add">
                <BiPlusCircle stroke="red" />
            </button>
        )
    }

    return (
        <div className="config-user-filtro">
            {/* Status do usuário */}
            <FiltroItemComponent
                type="select"
                id="userStatus"
                name="userStatus"
                title="Status do usuário"
                onChange={props.onChange}
                childrens={
                    user_status.map((_class, index) => (
                        <option key={index} value={_class.id}>{_class.name}</option>
                    ))
                }
            />

            {/* Button */}
            <ButtonComponent
                text="Buscar"
                type="submit"
                onClick={props.onClick}
                class="config-user-filtro-button"
            />

            {/* Button reset */}
            <ButtonComponent
                text="Resetar"
                type="reset"
                onClick={props.onReset}
                class="config-user-filtro-button config-user-filtro-button-reset"
            />

            {/* Adicionar Usuário */}
            { showButtonAddUser() }
        </div>
    )
}

export default UsuarioFiltroComponent;