import React from "react"

// CSS //
import './bar-skeleton.css'

const ComponentBarSkeleton = () => {
    return (
        <div className="component-bar-skeleton">
            <div className="klipbox-graph-title">
                <h1 className="klipbox-skeleton-body klipbox-skeleton-loading">Component Pie Skeleton Loading</h1>
            </div>
            <div className="klipbox-graph-legend">
                <p className="klipbox-skeleton-body klipbox-skeleton-loading">Component Pie Skeleton Legend Loading. Well, i'ts working! Nice!</p>
            </div>

            <div className="component-bar-skeleton-content">
                {Array(16).fill(0).map((item, index) => (
                    <div key={index} className="klipbox-skeleton-body klipbox-skeleton-loading component-bar-skeleton-graph-line" />
                ))}
            </div>
        </div>
    )

}

export default ComponentBarSkeleton