import React, { useState } from "react";
import { BiUser, BiLockAlt } from 'react-icons/bi'
import { useNavigate } from "react-router-dom";
import {environment} from '../../common/environments'

import "./page.css"

// Contexts //
import { useAuth } from '../../context/auth.context'

// Files //
import logo from '../../files/images/marca-logo-positivo.png'

// Components //
import InputComponent from "../../component/input";
import ButtonComponent from "../../component/button";

// Services //
import { AlertsService } from "../../services/alerts.service";

// Types //
import { LoginData } from "../../@types/login.types";

const Login: React.FC = () => {

    const navigate = useNavigate()
    const { signIn } = useAuth()
    const [isLogging, setLogging] = useState(false)
    const [data, setData] = React.useState<LoginData>({
        email: '',
        password: '',
        isPermanent: false
    });

    function handleChange(event: any) {
        const { target } = event
        const { name, value } = target

        switch (name) {
            case "password": {
                return setData({ email: data.email, password: value, isPermanent: data.isPermanent })
            }
            case "email": {
                return setData({ email: value, password: data.password, isPermanent: data.isPermanent })
            }
            case "isPermanent": {
                return setData({ email: data.email, password: data.password, isPermanent: !data.isPermanent })
            }
        }
    }

    function redirectToDashboard() {
        const timeout = setTimeout(() => {
            clearTimeout(timeout)
            navigate("/klipbox/repercussao")
        }, 2500)
    }

    function handleSubmit(event: any) {
        event.preventDefault()
        if (isLogging)
            return;

        // Set logging //
        setLogging(true)
        // Autentica //
        signIn(data)
            // Sucesso //
            .then((data) => {
                // Ocorreu um erro //
                if (data.error)
                    return

                redirectToDashboard()
            })
            // Error //
            .catch((err) => {
                console.log(err)
                AlertsService.notification({ icon: "error", title: err.response.data.message })
            })
            .finally(() => setLogging(false))
    }

    return (
        <div className="login-container login-align-itens">
            <div className="login-box">
                {/* Header */}
                <div className="login-box-header">
                    <img src={logo} alt="logo"></img>
                </div>

                {/* Content */}
                <div className="login-box-content">
                    <form onSubmit={handleSubmit}>
                        {/* E-mail input */}
                        <InputComponent
                            name="email"
                            placeholder="E-mail"
                            type="email"
                            icon={<BiUser key={"user-icon"} />}
                            hasIcon={true}
                            onChange={handleChange}
                        />

                        {/* Password input */}
                        <InputComponent
                            name="password"
                            placeholder="Senha"
                            type="password"
                            icon={<BiLockAlt key={"password-icon"} />}
                            hasIcon={true}
                            onChange={handleChange}
                        />

                        {/* Always Logged input */}
                        <InputComponent
                            name="isPermanent"
                            type="checkBox"
                            onChange={handleChange}
                            style={{
                                width: "none",
                                paddingTop: "15px",
                                marginTop: "12px",
                                marginLeft: "5px"
                            }}
                            hasLabel={true}
                            label="Manter-se conectado"
                        />

                        {/* Button send */}
                        <ButtonComponent
                            text="Entrar"
                            type="submit"
                            style={{
                                marginTop: "28px",
                                backgroundColor: "#2a79bd",
                                padding: ".45vw",
                                borderRadius: "10px"
                            }}
                        />
                    </form>
                </div>

                {/* Footer */}
                <div className="login-box-footer">
                    <p>Powered by <a href="https://imarketing.digital/" target="_blank" rel="IMTracing noreferrer">iMarketing Agência Digital</a></p>
                    <p>Version: {environment.VERSION}</p>
                </div>
            </div>
        </div>
    )
}

export default Login;