import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { SubMenuItemProps } from "../../@types/component.types";

// CSS //
import "./sub-menu-item.css";

const SubMenuItemComponent = (props: SubMenuItemProps) => {
    const navigator = useNavigate()
    const location = useLocation()

    const className = "dashboard-sub-menu-item"
        + (location.pathname === props.redirectTo ? " dashboard-sub-menu-item-line" : "")


    const buildIcon = () => {
        if (!props.hasIcon)
            return;
        return (
            <div className="dashboard-sub-menu-item-icon">
                <span>{props.icon}</span>
            </div>
        )
    }

    const onClick = () => navigator(props.redirectTo, { replace: true })
    return (
        <div className={className} onClick={onClick}>
            {/* Icon */}
            {buildIcon()}

            {/* Text */}
            <div className="dashboard-sub-menu-item-name">
                <p>{props.name}</p>
            </div>
        </div>
    )
}

export default SubMenuItemComponent;