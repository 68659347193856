import { useEffect, useState } from "react";
import { Notifications } from '../../@types/notifications.types'
import { RequestsService } from "../../services/requests.service";

// Component //
import NotificationItemComponent from "../notification-item";
import NotificationSkeletonComponent from "./notification-skeleton";

// CSS //
import "./notification.css";

const NotificationComponent = () => {
    const [notifications, setNotifications] = useState([] as Notifications[])
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {

        const getNotifications = setTimeout(() => {
            setNotifications([
                {
                    type: "Atualização",
                    title: "Olá mundo! Esse é uma notificação teste.",
                    description: "Talvez eu tenha consigo fazer o que eu realmente queria. Poderia ser que sim?"
                },
                {
                    type: "Atualização",
                    title: "Olá mundo! Esse é uma notificação teste.",
                    description: "Talvez eu tenha consigo fazer o que eu realmente queria. Poderia ser que sim?"
                },
                {
                    type: "Atualização",
                    title: "Olá mundo! Esse é uma notificação teste.",
                    description: "Talvez eu tenha consigo fazer o que eu realmente queria. Poderia ser que sim?"
                }
            ])

            setLoading(false)
        }, 3500);

        return () => {
            clearTimeout(getNotifications)
        }
    }, [])

    return (
        <div className="component-notification-build">
            <div className="component-notification">
                {/* HEADER */}
                <div className="component-notification-header">
                    <h4>Notificações</h4>
                </div>

                {/* CONTENT */}
                <div className="component-notification-content">
                    {isLoading ? <NotificationSkeletonComponent /> : <NotificationItemComponent notifications={notifications} />}
                </div>
            </div>
        </div>
    )
}

export default NotificationComponent;