import moment from 'moment'
import { BiCaretDown, BiCaretDownCircle, BiCaretUp, BiCaretUpCircle, BiCheck, BiCheckCircle, BiX, BiXCircle } from 'react-icons/bi'

// Types //
import { TableProps } from '../../@types/component.types'
import { Users } from '../../@types/users.types'

// Component //
import TableSkeletonComponent from './skeleton'

// CSS /
import './table.css'

// Image //
import user_image from '../../files/images/user.png'

// Utilitaries //
import { formatPhoneNumber } from '../../common/utilitaries'

const ComponentTable = (props: TableProps) => {

    const constructTableBody = (data: any, index: number) => {
        switch (props.type) {
            case "users": {
                const _data: Users = data
                const { groups, modules, deleted_at, created_at, updated_at } = _data

                return (
                    <div className="component-table-body-item" key={index}>
                        {/* Divisions */}
                        <div className="component-table-body-item-divisions">
                            <div className="component-table-body-item-division" style={{ width: "35%" }}>
                                <img alt={`user-icon-${index}`} src={ _data.photo || user_image } />
                                <h4>{_data.name}</h4>
                                <p>{_data.email}</p>
                            </div>
                            <div className="component-table-body-item-division">
                                <h4>{formatPhoneNumber(_data.phone)}</h4>
                            </div>
                            <div className="component-table-body-item-division">
                                { groups.length > 0 ? groups.map((g, index) => <h5 key={index}>{g.name}</h5>) : <h5>Nenhum grupo</h5> }
                            </div>
                            <div className="component-table-body-item-division">
                                <h4 className={deleted_at ? "component-table-inactive": "component-table-active"}>
                                    { deleted_at ? "INATIVO": "ATIVO" }
                                </h4>
                            </div>
                            <div className="component-table-body-item-division">
                                <button id={`user-show-up-${_data.id}`} type='button' onClick={() => props.actions.info(_data)} title="Ocultar informações" style={{ display: "none" }}>
                                    <BiCaretUp />
                                </button>
                                <button id={`user-show-down-${_data.id}`} type="button" onClick={() => props.actions.info(_data)} title="Ver informações">
                                    <BiCaretDown />
                                </button>
                                {
                                    !_data.deleted_at ? 
                                    <button 
                                        className="component-table-button-exclude" 
                                        type='button' 
                                        onClick={() => props.actions.delete(_data)} 
                                        title="Excluir usuário"
                                    >
                                        <BiX />
                                    </button> :
                                    <button 
                                        className="component-table-button-active" 
                                        type='button' 
                                        onClick={() => props.actions.active(_data)} 
                                        title="Ativar usuário"
                                    >
                                        <BiCheck />
                                    </button>
                                }
                            </div>
                        </div>

                        {/* Users Info */}
                        <div className="component-table-body-item-infos" id={`user-config-${_data.id}`} style={{ display: 'none' }}>
                            <div className="component-table-body-item-info">
                                <h4>Criado em: </h4>
                                <p>{`${moment(created_at).format("DD/MM/YYYY")} às ${moment(created_at).format("HH:MM:SS")}`}</p>
                            </div>
                            <div className="component-table-body-item-info">
                                <h4>Módulos: </h4>
                                { modules.length > 0 ? modules.map((g, index) => <h5 key={index}>{g.name}</h5>) : <p>Nenhum módulo</p> }
                            </div>
                            <div className="component-table-body-item-info">
                                <h4>Última atualização de perfil: </h4>
                                <p>{`${moment(updated_at).format("DD/MM/YYYY")} às ${moment(updated_at).format("HH:MM:SS")}`}</p>
                            </div>
                            <div className="component-table-body-item-info">
                                <button 
                                    type="button" 
                                    onClick={() => props.actions.updateInfo(_data)}
                                    title="Atualizar informações"
                                    className="component-table-item-button"
                                >
                                    Atualizar informações
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    }
    
    if (!props.dataIsLoaded) {
        return <TableSkeletonComponent />
    }

    return (
        <div className="component-table">
            {/* HEADER */}
            <div className="component-table-header">
                { props.titles.map((name, index) => <h4 key={index} style={index === 0 ? props.firstStyle : {}}>{name}</h4> ) }
            </div>

            {/* BODY */}
            <div className="component-table-body">
                {/* ITEM */}
                { props.data.map((data, index) => constructTableBody(data, index)) }
            </div>
        </div>
    )
}

export default ComponentTable