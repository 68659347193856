import { useNavigate } from "react-router-dom";
import { MenuItemProps } from "../../@types/component.types";

// CSS //
import "./menu-item.css";

const MenuItemComponent = (props: MenuItemProps) => {
    const navigator = useNavigate()

    const inRoute = props.name === props.route
    const addProps = props.class === undefined ? "" : " " + props.class
    const className = "component-menu-item" + addProps + (inRoute ? " component-show-transparent" : "")

    const buildIcon = () => {
        if (!props.hasIcon)
            return;

        return (
            <span>{props.icon}</span>
        )
    }

    const buildInRoute = () => {
        if (!inRoute) {
            return;
        }
        return (
            <div className="component-menu-item-show" />
        );
    }

    const onClick = () => navigator(props.redirectTo, { replace: true })
    return (
        <div className={className} onClick={onClick}>
            {/* ICON */}
            {buildIcon()}

            {/* Menu name */}
            <p>{props.name}</p>

            {/* Build in route */}
            {buildInRoute()}
        </div>
    )
}

export default MenuItemComponent