import { useState, useEffect } from "react";
import { BiNews } from "react-icons/bi";

// Service //
import { AlertsService } from "../../services/alerts.service";
// Types //
import { KlipboxFetch, KlipboxNoticia } from "../../@types/klipbox.types";
import { KlipboxProps } from "../../@types/component.types";

// Context //
import { useKlipbox } from "../../context/klipbox.context";

// Component //
import KlipboxNoticiasItem from "../klipbox-noticias-item";
import KlipboxNoticiasSkeletonComponent from "./klipbox-notificas-skeleton";
import KlipboxNoticiaComponent from "../klipbox-noticia";
import ComponentInteraction from "../interaction";

// CSS //
import "./klipbox-noticias.css"

const KlipboxNoticiasComponent = (props: KlipboxProps) => {

    const { fetchNews } = useKlipbox()

    var [scrollLoading] = useState(false)
    const [noticiaLoading, setNoticiaLoading] = useState(false)

    /**
     * @name requestKlipboxNews - Obtém as noticias do Klipbox
     * 
     * @param isUpdate 
     */
    const requestKlipboxNews = async (params?: KlipboxFetch, isUpdate?: boolean) => {
        try {
            const nextPage = props.statics.page + 1
            if (params) {
                params['page'] = isUpdate ? nextPage : 1
            }

            // Verificando se as notícias já não foram buscadas //
            if (props.statics.isFetched && !isUpdate) {
                return;
            }

            const news = await fetchNews(params)
            if (!news)
                return

            const { message, success, data } = news
            if (!success && message !== undefined) {
                return AlertsService.notification({ icon: "error", title: message })
            }

            // Verificando se existe uma data //
            if (data) {
                if (isUpdate) {
                    props.noticias.push(...data)
                } else {
                    props.setNoticias(data)
                    AlertsService.notification({ icon: "success", title: message })
                }
            }

            props.updateStatics(news, isUpdate)
        } catch (e) {
            console.log(e)
            if (e !== "ERR_CANCELED") {
                AlertsService.notification({ icon: "error", title: "Ocorreu um erro ao buscar as notícias do Klipbox." })
            }
        } finally {
            scrollLoading = false
            props.setLoading(false)
        }
    }

    /**
     * @name onScroll - Função que busca novas notícias ao chegar no final
     * 
     * @param e 
     */
    const onScroll = (e: any) => {
        if (props.isLoading || scrollLoading)
            return;

        const { target } = e;
        const { scrollHeight, scrollTop, clientHeight } = target;
        if (scrollHeight - scrollTop < (clientHeight + 150)) {
            const nextPage = props.statics.page + 1
            if (nextPage <= props.statics.totalPage && !scrollLoading) {
                scrollLoading = true
                requestKlipboxNews(props.scrollParameters, true)
            }
        }
    }

    /**
     * @name changeNoticia - Mostra uma notícia clicada
     * 
     * @param newNoticia 
     */
    const changeNoticia = (newNoticia: KlipboxNoticia) => {
        if (props.noticiaId === newNoticia.id)
            return;

        setNoticiaLoading(true)
        props.setNoticiaId(newNoticia.id)
    }

    /* GET KLIPBOX NEWS */
    useEffect(() => {
        const requestNews = setTimeout(() => requestKlipboxNews({ }, false), 1500)
        return () => clearTimeout(requestNews)
    }, [])

    if (props.isLoading || !props.statics.isFetched) {
        return <KlipboxNoticiasSkeletonComponent />
    }

    return (
        <div className="component-klipbox-news">
            <KlipboxNoticiasItem
                noticias={props.noticias}
                statics={props.statics}
                onScroll={onScroll}
                showNews={changeNoticia}
            />

            {/* Noticia */}
            {props.noticiaId !== -1 ?
                <KlipboxNoticiaComponent
                    noticias={props.noticias}
                    setNoticias={props.setNoticias}
                    noticiaId={props.noticiaId}
                    isLoading={noticiaLoading}
                    setLoading={setNoticiaLoading}
                    updateValues={props.updateValues}
                />
                :
                <ComponentInteraction icon={<BiNews />} text="Selecione uma notícia para ter a visualização completa" />
            }
        </div>
    )
}

export default KlipboxNoticiasComponent;